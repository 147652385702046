import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';
import { UsersReadInput } from '~/services';

export const useUsers = (params: UsersReadInput) => {

	const [ state, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin,
			loading.effects.admin,
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	useEffect(() => {

		dispatch.admin.readUsers(params);

	}, [ dispatch.admin, params ]);

	return {
		...state,
		...dispatch.admin,
		loading,
	};

}
