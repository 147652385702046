import { useEffect } from 'react';

import {
	Route,
	RouteProps,
	Redirect,
	RedirectProps,
	RouteChildrenProps
} from 'react-router-dom';

import { UserType } from '../';
import { useAuth, Status } from '~/store';
import { routes, PAGE_SIGNIN } from '~/constants';

export interface ProtectedRouteOptions {
	frame?: React.FC,
	title?: string,
	framed?: boolean,
	className?: string
}

export interface ProtectedComponent extends ProtectedRouteOptions, RouteChildrenProps {}

export type ProtectedFC<T extends {} = {}> = React.FC<T & ProtectedComponent>

export interface ProtectedRouteProps extends ProtectedRouteOptions, RouteProps {
	name?: number,
	roles?: ProtectedRoles,
	hasMenu?: boolean,
	component: ProtectedFC
}

export type ProtectedRoles = -1 | 0 | 1 | UserType[]

type AuthResponse =
	[ false, RedirectProps ] |
	[ false ] |
	[ true ]

function exitTo (role: UserType, routes: ProtectedRouteProps[], full: boolean): ProtectedRouteProps;
function exitTo (role: UserType, routes: ProtectedRouteProps[]): string
function exitTo (role: UserType, routes: ProtectedRouteProps[], full?: boolean): ProtectedRouteProps | string {

	if (!routes.length) {
		return '';
	}

	const route = routes
		.filter(
			({ roles: r = 0 }) => {
				return r !== -1 ? r === 1 || r === 0 || r.includes(role) : false
			}
		)[0];

	if (!route) {
		return '';
	}

	if (full) {
		return route;
	}

	const { path } = route;

	return Array.isArray(path) ? path[0] : path || '';

}

export const authorization = (
	status: Status,
	roles: ProtectedRoles = 0,
	role?: UserType
): AuthResponse => {

	switch (status) {
		case Status.authorized:
			const to = exitTo(role!, routes);
			switch (roles) {
				case 1:
					return [ true ];
				case 0:
					return [ true ];
				case -1:
					return [ false, { to } ];
				default:
					return roles.includes(role!) ?
						[ true ] :
						[ false, { to } ];
			}
		case Status.authorizing:
			switch (roles) {
				case 1:
					return [ false ];
				case 0:
					return [ true ];
				case -1:
					return [ false ];
				default:
					return [ false ];
			}
		case Status.unauthorized:
			switch (roles) {
				case 1:
					return [ false, { to: PAGE_SIGNIN } ];
				case 0:
					return [ true ];
				case -1:
					return [ true ];
				default:
					return [ false, { to: PAGE_SIGNIN } ];
			}
	}

}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {

	const {
		name,
		roles,
		title,
		framed,
		className,
		component: Component,
		...route
	} = props;

	const { retrieve, status, account } = useAuth();

	const [ result, redirect ] = authorization(
		status,
		roles,
		account?.user_type,
	);

	useEffect(() => { retrieve() }, [ name, retrieve ]);

	if (!result) {
		return redirect ?
			<Redirect {...redirect} /> :
			null;
	}

	return (
		<Route
			{...route}
			render={(props) => (
				<Component
					{...props}
					{...route}
					title={title}
					framed={framed}
					className={className} />
			)} />
	);

}
