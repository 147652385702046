import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OptionDefault } from '~/types';
import { DataType, RefDataResponse } from '~/services';
import { DataKeys, dataUtils } from '../models/Data';
import { Store } from '../models';

export const useData = () => {

	const [ data, loading ] = useSelector(
		({ data, loading }: Store.Root) => [
			data,
			loading.effects.data
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const getById = useCallback(
		<T extends DataKeys>(key: T, ids: string[]): RefDataResponse[T] => {

			return data[key].filter(
				({ id }) => ids.includes(id)
			) as RefDataResponse[T];

		},
		[ data ]
	);

	const itemToOption = useCallback(
		(item?: DataType.Union): OptionDefault | undefined => item ?
			dataUtils.createOptions([item])[0] :
			undefined,
		[]
	);

	const itemsToOptions = useCallback(
		(items: DataType.Union[]): OptionDefault[] =>
			dataUtils.createOptions(items),
		[]
	);

	const getOptionById = useCallback(
		<T extends DataKeys>(key: T, id?: string): OptionDefault | undefined =>
			id ? data.options[key].filter(({ value }) => value === id)[0] : undefined,
		[ data ]
	);

	const getOptionsByIds = useCallback(
		<T extends DataKeys>(key: T, ids: string[]): OptionDefault[] =>
			data.options[key].filter(({ value }) => ids.includes(value)),
		[ data ]
	);

	const optionToItem = useCallback(
		<T extends DataKeys>(key: T, option: OptionDefault) =>
			data[key].filter(({ id }) => id === option.value)[0],
		[ data ]
	);

	const optionsToItems = useCallback(
		<T extends DataKeys>(key: T, options: OptionDefault[]) => {
			const ids = options.map(({ value }) => value);
			return data[key].filter(({ id }) => ids.includes(id));
		},
		[ data ]
	);

	return {
		...data,
		...dispatch.data,
		loading,
		itemToOption,
		getById,
		getOptionById,
		getOptionsByIds,
		optionToItem,
		optionsToItems,
		sportOptions: data.options.sports,
		schoolOptions: data.options.schools,
		util: {
			itemToOption,
			itemsToOptions,
			optionById: getOptionById,
			optionsByIds: getOptionsByIds,
			optionToItem,
			optionsToItems,
		},
		isLoaded: !!data.sports.length,
	};

}
