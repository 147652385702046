import { pick } from 'lodash';
import { useForm } from 'react-hook-form';

import { lang, linkRegex } from '~/constants';

import { socialLinks, SocialLinksForm } from '~/services';
import { Button, Input } from '~/components';

import { StepProps } from '~/services/signup';

const regex = new RegExp(linkRegex, 'i');

export const SignUpSocials: React.FC<StepProps> = (props) => {

	const {
		step: [ , setStep ],
		data: [ data, setData ],
		steps,
	} = props;

	const { control, handleSubmit } = useForm<SocialLinksForm>({
		defaultValues: pick(data.links, Object.keys(socialLinks))
	});

	const submit = async (links: SocialLinksForm) => {

		setData((data) => ({
			...data,
			links,
		}));

		steps.next && setStep(steps.next);

	}

	return (
		<>
			<h2>{lang.SIGNUP_SURVEY_SOCIALS_TITLE}</h2>
			<div className="fields">
				{Object.values(socialLinks).map(([ , icon, name ], i) => (
				<Input
					key={i}
					name={name}
					icon={icon}
					rules={{
						validate: (link) => link && !regex.test(link) ?
							'Please enter valid link' :
							true
					}}
					control={control}
					placeholder="Link" />
				))}
			</div>
			<div className="btns">
				<Button
					label="Save"
					onClick={handleSubmit(submit)}
					variant="primary" />
				<Button
					label="Skip"
					onClick={() => steps.next && setStep(steps.next)} />
			</div>
		</>
	);

}
