import { api, Response } from '../api';

import { IAccountAthlete } from './Account';
import { IPartner } from './Partners';
import { LocationType } from './Location';
import { RequestType } from './Request';
import { PickByKey } from '~/types';
import { ILocation } from '.';

export type ServiceType =
	'personal_appearance' |
	'speaking_engagement' |
	'training_session' |
	'endorsement' |
	'video_chat' |
	'shoutout' |
	'camp'

export const Appearances = {
	sing: ['SING', 'Willing to sing'],
	visit_churches: ['VISIT_CHURCHES', 'Visit churches'],
};

export type AppearanceType = keyof typeof Appearances;

export type PayableKeys = Exclude<RequestType, 'endorsement_campaign'>
type GenericKeys = `${PayableKeys}_enabled` | `${PayableKeys}_rate`;
export type GenericServices = {
	// eslint-disable-next-line
	[SK in GenericKeys]: SK extends `${infer T}_rate` ? number : boolean
}

export interface IAthleteServices extends GenericServices {
	appearance_type: string
}

export interface IServices extends IAthleteServices {
	athlete_id: string,
	athlete_locations: (LocationType | ILocation)[]
}

export interface IAthleteInfo {
	athlete_id: string,
	school_name: string,
	school_id: string,
	school_email: string,
	sports_played: string,
	sports: string,
	badge: string,
	athlete_services: IServices,
	athlete_partners: Required<IPartner>[] | null
}

export interface IAthleteInfoStripped extends Omit<IAthleteInfo, 'athlete_services'> {
	athlete_services: IAthleteServices & {
		athlete_id: string,
		athlete_locations: (LocationType & { athlete_id: string })[]
	}
}

export interface IAthlete {
	id: string,
	username: string,
	first_name: string,
	last_name: string,
	profile_photo: string,
	school_id: string,
	school_name: string,
	sports_played: string,
	endorsement_rate: number
}

export interface IReview {
	athlete_id: string,
	request_id: string,
	rate: number,
	reviewer_name: string,
	reviewer_image: string,
	submitted_at: number,
	user_id: string
}

export interface IShoutout {
	names_in_shoutout: string,
	recipients_emails: string,
	request_id: string,
	special_message_info: string,
	special_occasion_info: string,
	created_at: number,
	updated_at: number,
	video: string
}

type ReadResponse = {
	athlete: IAccountAthlete,
	reviews: IReview[],
	shoutouts: IShoutout[]
}

type ServicesKeys = [
	keyof PickByKey<GenericServices, '_enabled'>,
	keyof PickByKey<GenericServices, '_rate'>
]

type ServiceRollType = {
	label: string,
	note?: string,
	icon: string,
	key: PayableKeys,
	keys: ServicesKeys,
	location: boolean,
	priority: number
}

export const servicesRoll: Record<PayableKeys, ServiceRollType> = {
	personal_appearance: {
		label: 'In person appearance',
		note: '(for 1 hour)',
		icon: 'ServiceAppearance',
		key: 'personal_appearance',
		keys: [
			'personal_appearance_enabled',
			'personal_appearance_rate',
		],
		location: true,
		priority: 70,
	},
	speaking_engagement: {
		label: 'Speaking Engagement',
		icon: 'ServiceAppearance',
		key: 'speaking_engagement',
		keys: [
			'speaking_engagement_enabled',
			'speaking_engagement_rate',
		],
		location: false,
		priority: 10,
	},
	training_session: {
		label: 'Training Session',
		note: '(for 1 hour)',
		icon: 'ServiceAppearance',
		key: 'training_session',
		keys: [
			'training_session_enabled',
			'training_session_rate',
		],
		location: true,
		priority: 20,
	},
	endorsement: {
		label: 'Endorsement',
		icon: 'ServiceEndorsement',
		key: 'endorsement',
		keys: [
			'endorsement_enabled',
			'endorsement_rate',
		],
		location: false,
		priority: 40,
	},
	video_chat: {
		label: 'Video chat',
		note: '(for 5 minutes)',
		icon: 'ServiceVideo',
		key: 'video_chat',
		keys: [
			'video_chat_enabled',
			'video_chat_rate',
		],
		location: false,
		priority: 50,
	},
	shoutout: {
		label: 'Shoutout',
		icon: 'ServiceShoutout',
		key: 'shoutout',
		keys: [
			'shoutout_enabled',
			'shoutout_rate',
		],
		location: false,
		priority: 60,
	},
	camp: {
		label: 'Camp',
		note: '(for 1 hour)',
		icon: 'ServiceAppearance',
		key: 'camp',
		keys: [
			'camp_enabled',
			'camp_rate',
		],
		location: true,
		priority: 30,
	},
};

export const servicesMap = Object.values(servicesRoll)
	.sort((a, b) => a.priority > b.priority ? -1 : 1 )
	.map(service => service);

export const formatServices = (data: IServices) => {

	const entries = Object.entries(data)
		.filter(([ i ]) => i.match(/_enabled/i))
		.map(
			([ i, enabled ]: [ string, boolean ]) => ({
				key: i.replace(/_enabled/, ''),
				enabled
			})
		) as { key: PayableKeys, enabled: boolean }[];

	const services = entries.map(({ key, enabled }) => ({
		key,
		rate: data[`${key}_rate`],
		enabled,
	}));

	const enabled = services.filter(
		({ enabled }) => enabled
	);

	const invalid = enabled.filter(
		({ rate }) => rate <= 0
	);

	return {
		services,
		enabled,
		invalid,
		isValid: enabled.length > 0 && invalid.length < 1,
	};

}

export const getActiveServices = (services: IAthleteServices): (ServiceRollType & { amount: number })[] => {

	return Object.values(servicesRoll)
		.filter(({ keys }) => services[keys[0]])
		.map((service) => ({
			...service,
			amount: services[service.keys[1]],
		}));

}

type PAResponse = { name: string, state: boolean }
function parseAppearances (val: string, arr: true): PAResponse[];
function parseAppearances (val: string, arr?: boolean): { [Type in AppearanceType]: boolean }
function parseAppearances (val: string, arr?: boolean) {

	const _val = val.split(', ');

	const _apps = Object.keys(Appearances) as AppearanceType[];
	const _appsObj = {} as { [Type in AppearanceType]: boolean };

	const _arr = _apps.map((app) => {
		const [ state, name ] = Appearances[app];
		_appsObj[app] = _val.includes(state);
		return { name, state: _val.includes(state) };
	});

	if (arr) {
		return _arr;
	}

	return _appsObj;
}

export const stringifyAppearances = (val: Record<AppearanceType, boolean>) => {

	return Object.entries(val)
		.filter(([ , isAvailable ]) => isAvailable)
		.map(([ key ]) => Appearances[key as AppearanceType][0])
		.join(', ');

}

export const SEARCH_FIELDS = {
	name: 'Name',
	sport: 'Sport',
	school: 'School',
}

export type SearchFieldKey = keyof typeof SEARCH_FIELDS

export const SEARCH_FIELDS_OPTIONS = Object.keys(SEARCH_FIELDS).map(
	(value) => ({ value, label: SEARCH_FIELDS[value as SearchFieldKey] }),
);

export const SEARCH_PLACEHOLDER: Record<string, string> = {
	name: 'Athlete name',
	sport: 'Sport',
	school: 'School name',
};

function read(params: { id: string }): Response<ReadResponse>
function read(params: { filter?: string, field?: string }): Response<{ athletes: IAthlete[] }>
function read(params: { id?: string, filter?: string, field?: string }): Response<any> {

	const { id, filter, field } = params;

	let path = '/public/athletes';
	let args: Record<string, string> = {};

	if (id) {
		args.athlete_id = id;
	} else {
		path += '/search';
		if (filter) { args.filter = filter }
		if (field) { args.field = field }
	}

	return api.get(path, args);

}

export const Athlete = {

	read,

	parseAppearances,

	stringifyAppearances,

	services: {

		update: (data: IAthleteServices) => api.put('/athlete/services', data, { type: 'JSON' }),

	},

	readEndorsement: (query: { filter?: string, field?: string }) => api.get<{
		athletes: IAthlete[]
	}>(
		`/public/athletes/endorsement/search`,
		query
	),

	recent: () => api.get<{
		athletes: IAthlete[]
	}>('/booking/athletes/recent'),

}
