import {
	Dispatch,
	SetStateAction,
	DetailedHTMLProps,
	InputHTMLAttributes,
	ButtonHTMLAttributes,
	SelectHTMLAttributes,
	VideoHTMLAttributes,
} from 'react';

import {
	Control,
	RegisterOptions,
	ControllerFieldState
} from 'react-hook-form';

export type Arrayable<T> = T | T[]

export type StateAction<T> = React.Dispatch<React.SetStateAction<T>>

export type ControlledProps = {
	name: string,
	rules?: RegisterOptions,
	control: Control<any>
}

export type ControlledInner = {
	state: ControllerFieldState
}

export type PlainOrMerge<T, M> = (T & M) | T

export type IfEquals<X, Y, A=X, B=never> =
	(<T>() => T extends X ? 1 : 2) extends
	(<T>() => T extends Y ? 1 : 2) ? A : B;

export type WritableKeys<T> = {
	[P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T];

export type DeepWritablePrimitive = undefined | null | boolean | string | number | Function;

export type DeepWritable<T> =
	T extends DeepWritablePrimitive ? T :
	T extends Array<infer U> ? DeepWritableArray<U> :
	T extends Map<infer K, infer V> ? DeepWritableMap<K, V> :
	T extends Set<infer T> ? DeepWriableSet<T> : DeepWritableObject<T>;

export type DeepWritableArray<T> = Array<DeepWritable<T>>
export type DeepWritableMap<K, V> = Map<K, DeepWritable<V>>
export type DeepWriableSet<T> = Set<DeepWritable<T>>

export type DeepWritableObject<T> = {
	[K in WritableKeys<T>]: DeepWritable<T[K]>
};

export type EmptyKeys<T> = {
	[P in keyof T]: {} extends T[P] ? P : never
}[keyof T];

export type OmitEmptyKeys<T> = Omit<T, EmptyKeys<T>>

export type FunctionPropertyNames<T> = {
	[K in keyof T]: T[K] extends Function ? K : never
}[keyof T];

export type ExcludeFunctions<T> = Omit<T, FunctionPropertyNames<T>>

export type StateDispatch<T> = Dispatch<SetStateAction<T>>

export type StateResponse<T> = [ T, StateDispatch<T> ]

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>

export type NonNullable<T> = Exclude<T, null>

export type Option<T = number | string> = {
	label: string,
	value?: T | string | number
}

export type Dimensions = {
	width: number,
	height: number
}

export type OptionDefault = {
	label: string,
	value: string,
}

// export type File = {
// 	type: string,
// 	name: string,
// 	uri: string
// }

export type PickKeysWith<T, S extends string> = {
	// eslint-disable-next-line
	[K in keyof T]: K extends `${infer G}${S}` ? K : never
}[keyof T]

export type PickByKey<T, S extends string> = Pick<T, PickKeysWith<T, S>>

export type ControlledField<T> = {
	inner: PlainOrMerge<T, ControlledInner>,
	outer: PlainOrMerge<T, ControlledProps>
}

export const getState = <T>(
	input: PlainOrMerge<T, ControlledProps> |
				 PlainOrMerge<T, ControlledInner>
): ControllerFieldState | null => {

	if (!('state' in input)) {
		return null;
	}

	return input.state;

}

export const getFieldState = <
C extends {},
T extends PlainOrMerge<C, ControlledInner>
>(i: T): ControllerFieldState | false => 'state' in i && i.state

export type GenericLoading<T extends string> = PartialRecord<T, boolean>

export type HTMLInput = DetailedHTMLProps<
	InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>

export type HTMLButton = DetailedHTMLProps<
	ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>

export type HTMLSelect = DetailedHTMLProps<
	SelectHTMLAttributes<HTMLSelectElement>,
	HTMLSelectElement
>

export type HTMLVideo = DetailedHTMLProps<
	VideoHTMLAttributes<HTMLVideoElement>,
	HTMLVideoElement
>
