import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createModel } from '@rematch/core';

import { Store } from './';

type AppState = {
	isMenuOpen: boolean
}

const state: AppState = {
	isMenuOpen: false,
};

export const app = createModel<Store.General>()({
	state,
	reducers: {
		SET_MENU: (state, isMenuOpen: boolean) => ({
			...state,
			isMenuOpen
		}),
	},
});

export const useAppState = () => {

	const { pathname } = useLocation();

	const { isMenuOpen } = useSelector(
		({ app }: Store.Root) => app
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const setMenu = useCallback(
		(state: boolean) => {

			dispatch.app.SET_MENU(state);

		},
		[ dispatch.app ]
	);

	useEffect(() => {

		setMenu(false);

	}, [ pathname, setMenu ]);

	useEffect(() => {

		document.body.style.overflow = isMenuOpen ?
			'hidden' :
			'auto';

	}, [ isMenuOpen ]);

	return [
		isMenuOpen,
		setMenu,
	] as const;

}
