import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useData } from '~/store';
import { getSportsByIds, lang, sports } from '~/constants';

import {
	AthleteService,
	Button,
	Legend,
	OptionView,
	SocialLink,
	Tabs,
	VideoWrap
} from '~/components';

import {
	IAccountAthlete,
	Location,
	ILocation,
	handleError,
	getActiveServices,
	socialLinksArray
} from '~/services';

interface ProfileAthleteProps {
	account: IAccountAthlete,
}

const profileTabs = [
	{ label: 'Info', },
	{ label: 'Video', },
	{ label: 'Services', },
];

export const ProfileAthlete: React.FC<ProfileAthleteProps> = (props) => {

	const { account } = props;

	const { push } = useHistory();

	const [ tab, setTab ] = useState(0);

	const { util } = useData();

	const [ locations, setLocations ] = useState<ILocation[]>([]);

	const [ loading, setLoading ] = useState(false);

	useEffect(() => {

		const getLocations = async () => {

			setLoading(true);

			try {

				const { locations } = await Location.read().promise;

				setLoading(false);

				setLocations(locations);

			} catch (e) {

				setLoading(false);

				handleError(e);

			}

		}

		getLocations();

	}, []);

	const {
		school_name,
		school_email,
		sports: sports_played,
		athlete_services: services
	} = account.athlete_info;

	const Services = getActiveServices(services)
		.map(({ key, keys, ...val }, i) => (
			<AthleteService
				key={i}
				type={key}
				locations={locations}
				{...val}
				amount={services[keys[1]]} />
		));

	Services.push(
		<div key="0" className="legend-extender">
			<Button
				label="Edit services"
				loading={loading}
				variant="primary"
				onClick={() => push('/settings/services')} />
		</div>
	);

	return (
		<Tabs
			tab={tab}
			tabs={profileTabs}
			onChange={setTab}>
			<div className="page--container">
				<Legend
					label="Verified Profile Information">
					<>
						<OptionView label="Gender" value={util.optionById('genders', account.gender)?.label || ''} />
						<OptionView label="Name of School" value={school_name} />
						<OptionView label="Verified School Email" value={school_email} hidden={!school_email} />
						<OptionView label="Sport Played" value={getSportsByIds(sports_played).join(', ')} />
					</>
					<div className="legend-extender">
						<Button
							label="Edit survey"
							variant="primary"
							onClick={() => push('/settings/survey')} />
					</div>
				</Legend>
				<Legend
					label="Social Networks">
					{socialLinksArray.map(([ , , key ], i) => (
					<SocialLink key={i} type={key} />
					))}
					<div className="legend-extender">
						<Button
							label="Edit Social Links"
							variant="secondary"
							onClick={() => push('/settings/links')} />
					</div>
				</Legend>
			</div>
			<div className="page--container">
				<VideoWrap
					source={{ uri: account.intro_video }} />
			</div>
			<div className="page--container">
				<Legend
					label={lang.SERVICES_PRICING}
					children={Services} />
			</div>
		</Tabs>
	);

}
