import { lang } from '~/constants';
import { Button, Modal } from '~/components';
import { useHistory } from 'react-router';

interface CampaignPromptProps {
	isVisible: boolean,
	setVisile: (state: boolean) => void
}

export const CampaignPrompt: React.FC<CampaignPromptProps> = (props) => {

	const { isVisible, setVisile } = props;

	const { push } = useHistory();

	const create = () => {

		setVisile(false);
		push('/request-form/b/endorsement_campaign');

	}

	return (
		<Modal
			icon="fancyQuestionMark"
			title="Create campaign"
			onHide={() => setVisile(false)}
			visible={isVisible}
			className="campaign--modal">
			<>
				<p
					className="message"
					children={lang.CAMPAIGN_MODAL_TEXT} />
				<Button
					label="Create"
					variant="primary"
					onClick={create} />
				<Button
					label="Cancel"
					onClick={() => setVisile(false)} />
			</>
		</Modal>
	);

}
