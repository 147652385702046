import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';
import { RequestsReadInput } from '~/services';

export const useUser = (id: string, params?: Omit<RequestsReadInput, 'user_id'>) => {

	const [ empty, setEmpty ] = useState(false);

	const [ user, requests, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			id ? admin.usersById[id] : null,
			id ? admin.requestsByUserId[id] : null,
			loading.effects.admin,
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const fetch = useCallback(
		async (id: string) => {

			const resp = await dispatch.admin.readUser(id);

			if (resp === null) {
				setEmpty(true);
			}

		},
		[ dispatch.admin ]
	);

	useEffect(() => {

		fetch(id)

	}, [ fetch, id ]);

	useEffect(() => {

		if (!params) {
			return;
		}

		dispatch.admin.readRequests({
			...params,
			user_id: id,
		});

	}, [ dispatch.admin, id, params ]);

	return {
		user,
		empty,
		requests,
		...dispatch.admin,
		loading,
	};

}
