import { confirmAlert, ReactConfirmAlertProps as Args } from 'react-confirm-alert';
import { RequestType, REQUEST_TYPE } from './modules';

type ConfirmOptions = Args |
	((resolve: (value: boolean | PromiseLike<boolean>) => void) => Args)

export const confirm = (options: ConfirmOptions) => new Promise<boolean>(
	(resolve) => {

		const args = typeof options === 'function' ?
			options(resolve) :
			{
				closeOnClickOutside: false,
				buttons: [{
					label: 'No',
					onClick: () => resolve(false),
				}, {
					label: 'Yes',
					onClick: () => resolve(true),
				}],
				...options,
			};

		confirmAlert(args);

	},
);

const logout = () => confirm((resolve) => ({
	title: 'Log out',
	message: 'Are you sure want to log out?',
	buttons: [{
		label: 'Cancel',
		onClick: () => resolve(false),
	}, {
		label: 'OK',
		onClick: () => resolve(true),
	}],
}));

const requestCancel = async (type: RequestType) => confirm((resolve) => ({
	title: 'Cancel request',
	message: `Are you sure want to cancel ${REQUEST_TYPE[type][0].toLowerCase()} request?`,
	buttons: [{
		label: 'No',
		onClick: () => resolve(false),
	}, {
		label: 'Cancel',
		onClick: () => resolve(true),
	}]
}));

const campaignCancel = async () => confirm((resolve) => ({
	title: 'Cancel campaign',
	message: `Are you sure want to cancel the endorsement campaign?`,
	buttons: [{
		label: 'No',
		onClick: () => resolve(false),
	}, {
		label: 'Cancel',
		onClick: () => resolve(true),
	}]
}));

const requestDecline = async () => confirm((resolve) => ({
	title: 'Decline request',
	message: 'Are you sure want to decline the request?',
	buttons: [{
		label: 'Cancel',
		onClick: () => resolve(false),
	}, {
		label: 'Decline',
		onClick: () => resolve(true),
	}],
}));

const videoChatLeave = async () => confirm((resolve) => ({
	title: 'Are you sure want to finish the call?',
	message: 'The call will be considered complete',
	buttons: [{
		label: 'Cancel',
		onClick: () => resolve(false),
	}, {
		label: 'Finish',
		onClick: () => resolve(true),
	}],
}));

const payoutsSend = async (amount: number, count: number) => confirm((resolve) => ({
	title: 'Confirm operation',
	message: `You are going to pay $${amount} to ${count} athlete${count > 1 ? 's' : ''}`,
	buttons: [{
		label: 'Cancel',
		onClick: () => resolve(false),
	}, {
		label: 'Pay',
		onClick: () => resolve(true),
	}],
}));

const editLeave = async (tab?: boolean) => confirm((resolve) => ({
	title: 'You have unsaved changes',
	message: `Are you sure want to close the ${tab ? 'tab' : 'modal'}?`,
	buttons: [{
		label: 'Cancel',
		onClick: () => resolve(false),
	}, {
		label: 'OK',
		onClick: () => resolve(true),
	}],
}));

const unitRemove = async (unit: string) => confirm((resolve) => ({
	title: 'Action confirm',
	message: `Are you sure want to remove the ${unit}?`,
	buttons: [{
		label: 'Cancel',
		onClick: () => resolve(false),
	}, {
		label: 'OK',
		onClick: () => resolve(true),
	}],
}));

export const _confirm = {
	logout,
	requestCancel,
	campaignCancel,
	requestDecline,
	videoChatLeave,
	payoutsSend,
	editLeave,
	unitRemove,
};
