import React, { useState } from 'react';

import { IAthlete, useAthleteSearch } from '~/services';
import { AthleteCard, Button, Search, SquareIcon, Title, Select } from '~/components';
import { Preview, Profile } from '~/containers/Athlete';

import { PageTitle } from './Page';
import { toFixed } from '~/utils';

export const athletesCount = (val: number): string => {

	if (!val) {
		return 'No athletes selected';
	}

	return `${val} athlete${val > 1 ? 's' : ''}`;

}

export const athletesSummary = (arr: IAthlete[]): [ number, number ] => {

	let count = 0;

	const price = arr.reduce(
		(price, { endorsement_rate }) => {
			count++;
			return price + endorsement_rate;
		},
		0
	);

	return [ count, price ];

}

export const athletesSummaryByForm = (obj: Record<string, number | string>): [ number, number ] => {

	if (!obj) {
		return [ 0, 0 ];
	}

	let count = 0;

	const price = Object.entries(obj).reduce(
		(price, [ id, amount ]) => {
			const _amount = Number(amount);
			if (_amount > 0) {
				count++;
				return price + Number(amount);
			} else {
				return price + 0;
			}
		},
		0
	);

	return [ count, price ];

}

export const _isInArray = <T extends { id: string }>(arr: T[], item: T) => {

	const _isFound = !!arr.filter(({ id }) => id === item.id)[0];

	return _isFound;

}

export const _pushOrRemove = <T extends { id: string }>(arr: T[], item: T) => {

	if (_isInArray(arr, item)) {
		return arr.filter(({ id }) => id !== item.id);
	}

	return [ item, ...arr ];

}

interface AthletesPickerProps {
	shown: boolean,
	onHide: () => void,
	summary: [number, number],
	selected: IAthlete[],
	onAthleteClick: (athlete: IAthlete, e: React.MouseEvent) => void,
}

export const AthletesPicker: React.FC<AthletesPickerProps> = (props) => {

	const { onHide, summary, selected, onAthleteClick } = props;

	const { athletes, loading, filter, field, text, trigger } = useAthleteSearch(true);

	const [ athlete, setAthlete ] = useState<{ id: string, tab: number } | null>(null);

	if (athlete) {
		return (
			<>
				{athlete.tab === 0 ? (
				<Preview
					onGoBack={() => setAthlete(null)}
					onGoServices={() => setAthlete((val) => val && ({ ...val, tab: 1 }))}
					athleteId={athlete.id} />
				) : (
				<Profile
					onGoBack={() => setAthlete((val) => val && ({ ...val, tab: 0 }))}
					athleteId={athlete.id} />
				)}
			</>
		);
	}

	const SearchAddon = (
		<Select
			value={field}
			options={text.SEARCH_FIELDS_OPTIONS}
			onChange={({ value }) => trigger('field', value as string)} />
	);

	return (
		<>
			<PageTitle
				title="Select athletes"
				onBack={onHide} />
			<div className="app--athletes-picker">
				<div className="app--athletes-picker-search">
					<SquareIcon
						icon="cross"
						onClick={onHide} />
					<Search
						loading={loading === 2}
						onReset={trigger}
						onSearch={(value) => trigger('filter', value)}
						placeholder={text.SEARCH_PLACEHOLDER[(field || 'name')]}
						searchAddon={SearchAddon}
						defaultValue={filter} />
				</div>
				<div className="grid grid-3s">
					{athletes.map((athlete) => (
					<AthleteCard
						key={athlete.id}
						athlete={athlete}
						onClick={() => setAthlete({ id: athlete.id, tab: 0 })}
						onSelect={onAthleteClick}
						isSelected={_isInArray(selected, athlete)} />
					))}
				</div>
				<div className="app--athletes-picker-footer">
					<div className="app--athletes-picker-footer-title">
						<Title label="Choose athletes" />
					</div>
					<div className="app--athletes-picker-footer-inner">
						<div className="app--athletes-summary">
							<p children={athletesCount(summary[0])} />
							<p children={`$${toFixed(summary[1])}`} />
						</div>
						<Button
							label="Continue"
							variant="primary"
							onClick={onHide}
							disabled={!selected.length}
							disabledDeep />
					</div>
				</div>
			</div>
		</>
	);

}
