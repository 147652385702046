interface BigAmountProps {
	amount: number | string,
	prefix?: string
}

export const BigAmount: React.FC<BigAmountProps> = (props) => {

	const { amount, prefix = '$' } = props;

	return (
		<p
			className="app--big-amount"
			children={typeof amount === 'number' ? `${prefix}${amount}` : amount} />
	);

}
