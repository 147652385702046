import { createModel } from '@rematch/core';

import { Store } from './';

import {
	Request,
	IRequest,
	IRequestExtra,
	RequestType,
	RequestReadOutput,
	IRequestExtended,
	RequestStatus,
	findAssociatedProfile,
	handleError
} from '~/services';

interface RequestsState {
	cached: boolean,
	refresh: boolean,
	requests: IRequestExtra[],
	requestsById: Record<string, IRequestExtended>,
}

const state: RequestsState = {
	cached: false,
	refresh: false,
	requests: [],
	requestsById: {},
};

export const requests = createModel<Store.General>()({
	state,
	reducers: {
		insert: (state, payload: RequestReadOutput) => {

			state.refresh = false;

			if ('request' in payload) {

				state.requestsById[payload.request.id] = {
					...payload.request,
					profile: payload.account,
					campaign: '',
				};

				return state;

			}

			if ('requests' in payload) {

				payload.requests.forEach(
					(request) => {
						state.requestsById[request.id] = {
							...request,
							profile: findAssociatedProfile(
								request,
								payload.accounts,
								3
							),
							campaign: request.endorsement.endorsement_campaign_id
						}
					}
				);

			}

			return state;

		},
		insertList: (state, requests: IRequestExtra[]) => {
			return {
				...state,
				cached: true,
				requests,
			};
		},
		appendList: (state, requests: IRequestExtra[]) => {
			return {
				...state,
				requests: [ ...state.requests, ...requests ],
			};
		},
		_update: (state, request: IRequest) => {

			const req = state.requestsById[request.id];

			if (!('profile' in req)) {
				return state;
			}

			(state.requestsById[request.id] as IRequestExtended) = {
				...req,
				...request,
			};

			return {
				...state,
				requests: state.requests.map(
					(val) => val.id !== request.id ? val : {
						...val,
						...request,
					},
				),
			};

		},
		_updateStatus: (state, { id, status }: { id: string, status: RequestStatus }) => {

			const req = state.requestsById[id];

			(state.requestsById[id] as IRequestExtended) = {
				...req,
				status,
			};

			return {
				...state,
				requests: state.requests.map(
					(val) => val.id !== id ? val : {
						...val,
						status,
					},
				),
			};

		},
		_updatePayStatus: (state, id: string, paid: boolean) => {

			const req = state.requestsById[id];

			if (!req || !('profile' in req)) {
				return state;
			}

			(state.requestsById[id] as IRequestExtended) = {
				...req,
				paid,
			};

			return {
				...state,
				requests: state.requests.map(
					(val) => val.id !== id ? val : {
						...val,
						paid,
					},
				),
			};

		},
		_reviewRequest: (state, { id }: { id: string }) => {

			const req = state.requestsById[id];

			(state.requestsById[id] as IRequestExtended) = {
				...req,
				reviewed: true,
			};

			return {
				...state,
				requests: state.requests.map(
					(val) => val.id !== id ? val : {
						...val,
						reviewed: true,
					},
				),
			};

		},
		_uploadVideo: (state, payload: { id: string, path: string, type: RequestType }) => {

			const { id, path, type } = payload;

			const req = state.requestsById[id];

			if (!('profile' in req) || (type !== 'endorsement' && type !== 'shoutout')) {
				return state;
			}

			(state.requestsById[id] as IRequestExtended) = {
				...req,
				status: 4,
				[type]: {
					...req[type],
					video: path,
				},
			};

			return {
				...state,
				requests: state.requests.map(
					(val) => val.id !== id ? val : {
						...val,
						status: 4,
						[type]: {
							...req[type],
							video: path,
						},
					},
				),
			};

		},
		_cancelCampaign: (state, id: string) => {

			for (const i in state.requestsById) {
				if (state.requestsById[i].campaign === id) {
					state.requestsById[i].status = 3;
				}
			}

			return {
				...state,
				requests: state.requests.map(
					(req) => req.endorsement_campaign_id === id ?
						{
							...req,
							status: 3
						} :
						req,
				),
			};

		},
		_updateRefresh: (state, refresh: boolean) => {
			return {
				...state,
				refresh,
			};
		},
		clear: () => {
			return state;
		},
	},
	effects: (dispatch) => ({
		retrieve: async ({ id, isCampaign, force }: { id: string, isCampaign: boolean, force?: boolean }, state) => {

			// if (!force && state.requests.requestsById[id]) {
			// 	return;
			// }

			if (force) {
				dispatch.requests._updateRefresh(true);
			}

			try {

				const response = await Request.readCurrent(id, isCampaign).promise;

				dispatch.requests.insert(response);

				return true;

			} catch (e) {

				return false;

			}

		},
		retrieveList: async ({ force }: { force?: boolean }, state) => {

			if (!force && state.requests.cached) {
				return;
			}

			try {

				const { requests } = await Request.read().promise;

				dispatch.requests.insertList(requests);

			} catch (e) {}

		},
		approve: async ({ id }: { id: string }) => {

			try {

				await Request.approve({ request_id: id }).promise;

				dispatch.requests._updateStatus({ id, status: 5 });

			} catch (e) {

				handleError(e);

			}

		},
		accept: async (id: string) => {

			try {

				await Request.accept(id).promise;

				dispatch.requests._updateStatus({ id, status: 2 });

			} catch (e) {

				handleError(e);

			}

		},
		review: async ({ id, rate }: { id: string, rate: number }) => {

			try {

				await Request.review({ request_id: id, rate }).promise;

				dispatch.requests._reviewRequest({ id });

			} catch (e) {

				handleError(e);

			}

		},
		cancel: async (id: string) => {

			try {

				await Request.cancel(id).promise;

				dispatch.requests._updateStatus({ id, status: 3 });

			} catch (e) {

				handleError(e);

			}

		},
		updatePayStatus: async ({ id, paid }: { id: string, paid: boolean }) => {

			dispatch.requests._updatePayStatus(id, paid);

		},
		decline: async (id: string) => {

			try {

				await Request.decline(id).promise;

				dispatch.requests._updateStatus({ id, status: 3 });

			} catch (e) {

				handleError(e);

			}

		},
		uploadVideo: async ({ id, request_type, file }: { id: string, request_type: RequestType, file: File }) => {

			try {

				const { path } = await Request.uploadVideo({
					file,
					type: request_type,
					request_id: id,
				}).promise;

				dispatch.requests._uploadVideo({
					id,
					path,
					type: request_type,
				});

			} catch (e) {}

		},
		cancelCampaign: async (id: string) => {

			try {

				await Request.cancelCampaign(id).promise;

				dispatch.requests._cancelCampaign(id);

			} catch (e) {}

		},
	}),
});
