import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { pick } from 'lodash';

import { lang, linkRegex } from '~/constants';
import { UserEditComponent } from '../modals/types';

import {
	socialLinks,
	SocialLinksForm
} from '~/services';

import {
	Button,
	Input,
	Legend
} from '~/components';

const regex = new RegExp(linkRegex, 'i');

export const UserEditSocialLinks: React.FC<UserEditComponent<SocialLinksForm>> = (props) => {

	const { loading, onSubmit, updated } = props;

	const [ , setIsUpdated ] = updated;

	const { control, handleSubmit, watch } = useForm<SocialLinksForm>({
		defaultValues: pick(props.data, Object.keys(socialLinks))
	});

	const submit = useCallback(
		(data: SocialLinksForm) => {

			onSubmit({
				tab: 'links',
				data
			});

		},
		[ onSubmit ]
	);

	useEffect(() => {

		const subscription = watch(() => {
			setIsUpdated(true);
		});

		return () => subscription.unsubscribe();

	}, [ watch, setIsUpdated ]);

	return (
		<>
			<Legend label={lang.SOCIALS_LABEL}>
				{Object.values(socialLinks).map(([ , icon, name ], i) => (
				<div key={i} className="field--wrapper">
					<Input
						name={name}
						icon={icon}
						rules={{
							validate: (link) => link && !regex.test(link) ?
								'Please enter valid link' :
								true
						}}
						control={control}
						placeholder="Link" />
				</div>
				))}
			</Legend>
			<Button
				label={lang.SOCIALS_SAVE}
				onClick={handleSubmit(submit)}
				variant="primary"
				loading={loading}
				disabledDeep />
		</>
	);

}
