import { useCallback, useEffect, useMemo, useState } from 'react';
import FileSaver from 'file-saver';

import { useAuth } from '~/store';
import { config } from '~/constants';
import { PageContent, PageTitle } from '~/containers';
import { StatsHead } from '../containers/Stats';
import { BigAmount, Button, Flag, Legend, Modal, OptionView } from '../components';

import {
	Business,
	BusinessStatus,
	BusinessStatusArray,
	handleError,
	RequestType,
	REQUEST_TYPE,
	StatsScheme
} from '~/services';

type State = StatsScheme & {
	loaded: boolean,
	loading: boolean
}

type ReportState = {
	type: 'pdf' | 'csv',
	sending?: boolean,
	loading?: boolean
}

const initialStats: State = {
	loaded: false,
	loading: false,
	counters: {
		spent_total: 0,
		requests_by_status: {
			pending: 0,
			accepted: 0,
			finished: 0,
			cancelled: 0,
			in_progress: 0,
			pending_review: 0,
		},
		accepted_rejected_total: {
			type: 'total',
			accepted: 0,
			rejected: 0,
		},
		spent_by_athletes: [],
		spent_by_request_types: [],
		accepted_rejected_by_type: [],
	},
	filename: '',
};

export const BusinessStats: React.FC = () => {

	const { account } = useAuth();

	const [
		{
			loaded,
			loading,
			counters,
		},
		setStats
	] = useState<State>(initialStats);

	const [ report, setReport ] = useState<ReportState | null>(null);

	const retrieve = useCallback(
		async () => {

			setStats((val) => ({
				...val,
				loading: true,
			}));

			try {

				const resp = await Business.getStats({
					send: false,
				}).promise;

				setStats({
					...resp,
					loaded: true,
					loading: false,
				});

			} catch (e) {

				handleError(e);

				setStats((val) => ({
					...val,
					loading: false,
				}));

			}

		},
		[]
	);

	useEffect(() => {

		retrieve();

	}, [ retrieve ]);

	const load = useCallback(
		async () => {

			if (!report?.type) {
				return;
			}

			setReport((val) => val && ({
				...val,
				loading: true,
			}));

			try {

				const { filename } = await Business.getStats({
					send: false,
					export: report?.type,
				}).promise;

				FileSaver.saveAs(
					config.URL_BASE + filename,
					`${report.type}-stat.${report.type}`
				);

			} catch (e) {

				handleError(e);

			} finally {

				setReport((val) => val && ({
					...val,
					loading: false,
				}));

			}

		},
		[ report?.type ]
	);

	const send = useCallback(
		async () => {

			if (!report?.type) {
				return;
			}

			setReport((val) => val && ({
				...val,
				sending: true,
			}));

			try {

				await Business.getStats({
					send: true,
					export: report?.type,
				}).promise;

				alert('You report has been sent');

			} catch (e) {

				handleError(e);

			} finally {

				setReport((val) => val && ({
					...val,
					sending: false,
				}));

			}

		},
		[ report?.type ]
	);

	const requestByType = useMemo(
		() => {

			const _map = {} as Record<RequestType, [ number, number ]>

			counters.accepted_rejected_by_type.forEach(
				({ type, accepted, rejected }) => _map[type] = [ accepted, rejected ]
			);

			return _map;

		},
		[ counters ]
	);

	if (!loaded || !account) {
		return (
			<PageTitle
				title="Statistics" />
		);
	}

	const ExportModal = (
		<Modal
			icon="bigArrowRight"
			title="Export report"
			className="export--modal"
			visible={!!report}
			onHide={() => setReport(null)}
			children={(
				<>
					<div className="flags">
						<Flag
							isSelected={report?.type === 'csv'}
							disabled={report?.loading || report?.sending}
							onClick={() => setReport({ type: 'csv' })}
							children="CSV" />
						<Flag
							isSelected={report?.type === 'pdf'}
							disabled={report?.loading || report?.sending}
							onClick={() => setReport({ type: 'pdf' })}
							children="PDF" />
					</div>
					<Button
						variant="primary"
						loading={report?.loading}
						label="Download report"
						onClick={load} />
					<Button
						disabled={!account.email}
						disabledDeep
						variant="secondary"
						loading={report?.sending}
						label="Send report to my email"
						onClick={send} />
					{!!account.email && (
					<p
						className="message"
						children={`The report will be sent to address ${account.email}`} />
					)}
					<Button
						onClick={() => setReport(null)}
						label="Cancel" />
				</>
			)} />
	);

	return (
		<>
			<PageTitle title="Statistics" />
			<PageContent pageContentClassName="page--stats">
				<StatsHead
					account={account} />
				<Legend label="Total spent">
					<BigAmount amount={counters.spent_total} />
					<>
						<OptionView
							label="Total accepted requests"
							value={`${counters.accepted_rejected_total.accepted}`} />
						<OptionView
							label="Total rejected requests"
							value={`${counters.accepted_rejected_total.rejected}`} />
					</>
				</Legend>
				<Legend label="Requests by status">
					<>
					{BusinessStatusArray.map((status, i) => (
					<OptionView
						key={i}
						label={`${BusinessStatus[status]} requests`}
						value={`${counters.requests_by_status[status]}`} />
					))}
				</>
				</Legend>
				<Legend label="Requests by type">
					<>
						<OptionView
							label="Request Type"
							value="Spent (Accepted/Rejected)" />
						{counters.spent_by_request_types.map(({ type, amount }, i) => (
						<OptionView
							key={i}
							label={`${REQUEST_TYPE[type][0]}`}
							value={`$${amount} (${requestByType[type][0]}/${requestByType[type][1]})`} />
						))}
					</>
				</Legend>
				<Legend label="Requests by athletes">
					<>
						{counters.spent_by_athletes.map(({ first_name, last_name, amount }, i) => (
						<OptionView
							key={i}
							label={`${first_name} ${last_name}`}
							value={`$${amount}`} />
						))}
					</>
				</Legend>
				<Button
					onClick={() => setReport({ type: 'csv' })}
					loading={loading}
					variant="primary"
					label="Export report" />
			</PageContent>
			{ExportModal}
		</>
	);

}
