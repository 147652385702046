import { PropsWithChildren, useMemo, CSSProperties } from 'react';
import cn from 'classnames';

interface GridProps<T> {
	gap?: number,
	content?: T[],
	rowCount: number,
	className?: string,
	children: (
		item: T,
		key: number,
	) => React.ReactNode,
	style?: CSSProperties
	itemStyle?: CSSProperties
}

export const Grid = <T,>(props: PropsWithChildren<GridProps<T>>) => {

	const {
		content,
		rowCount,
		children,
		className,
	} = props;

	const Content = useMemo<React.ReactNode[]>(
		() => {

			if (!content) {
				return [];
			}

			return content.map((item, i) => children(item, i));

		},
		[ content, children ]
	);

	if (typeof children !== 'function' || !content) {
		return null;
	}

	const classNames = cn(
		'app--grid',
		className,
		{ [`grid--n${rowCount}`]: rowCount }
	);

	return (
		<div
			children={Content}
			className={classNames} />
	);

}
