import { api } from '../api';

import { RequestType } from './Request';

export interface INotification {
	id: number,
	data: string,
	is_read: boolean
	user_id: string,
	reference_id: string,
	request_id: string,
	created_at: number,
	updated_at: number,
	request_type: RequestType,
	endorsement_campaign_id: string
}

export const isNotification = (val: any): val is INotification => {
	return typeof val.id !== 'undefined' &&
				 typeof val.data !== 'undefined' &&
				 typeof val.is_read !== 'undefined' &&
				 typeof val.user_id !== 'undefined' &&
				 typeof val.reference_id !== 'undefined' &&
				 typeof val.request_id !== 'undefined' &&
				 typeof val.created_at !== 'undefined' &&
				 typeof val.updated_at !== 'undefined' &&
				 typeof val.request_type !== 'undefined' &&
				 typeof val.endorsement_campaign_id !== 'undefined';
}

export enum DeviceType {
	android = 1,
	ios = 2
};

export const Notifications = {

	setToken: (data: {
		device_type: DeviceType,
		access_key: string,
		enabled: boolean
	}) => api.put('/devices', data, { type: 'JSON' }),

	read: () => api.get<{
		notifications: INotification[]
	}>('/user/notifications/list'),

	setRead: (ids: number[]) => api.put(
		'/user/notifications/set_read',
		{ ids: JSON.stringify(ids) },
		{ type: 'FORM' }
	),

	delete: (ids: number[]) => api.del(
		'/user/notifications/delete',
		{ ids: JSON.stringify(ids) },
		{ type: 'DATA' }
	),

};
