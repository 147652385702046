import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import useResizeObserver from 'use-resize-observer';
import { v4 } from 'uuid';

import { config, mapStyles } from '~/constants';
import { gMapsAPI } from '~/utils';
import { useLoading } from '~/hooks';
import { LocationType, RequestType } from '~/services';

import { BottomBar } from './BottomBar';
import { QuantitySelector } from './QuantitySelector';
import { SquareIcon } from './SquareIcon';
import { Search } from './Search';
import { Button } from './Button';

interface MapWrapProps {
	isVisible: boolean,
	noRadius?: boolean,
	locationType?: RequestType,
	onClose: () => void,
	onLocationCreate: (location: LocationType & { id: string }) => void,
}

const center = {
	lat: 34.0522342,
	lng: -118.2436849
};

const libraries: ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[] = ['places'];

const gMap = gMapsAPI({ key: config.MAPS_KEY });

export const MapWrap: React.FC<MapWrapProps> = (props) => {

	const {
		isVisible,
		noRadius,
		locationType,
		onClose,
		onLocationCreate
	} = props;

  const { ref, ...mapDimensions } = useResizeObserver<HTMLDivElement>();

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		libraries,
		googleMapsApiKey: config.MAPS_KEY,
	});

	const [ location, setLocation ] = useState<LocationType>({
		id: '',
		name: '',
		radius: 4,
		latitude: center.lat,
		longitude: center.lng,
		location_type: locationType || 'personal_appearance',
	});

	const [ loading, setLoading ] = useLoading<'search' | 'center'>();

	const [ map, setMap ] = useState<google.maps.Map | null>(null);

	const onSearch = useCallback(
		async (text: string) => {

			if (!map) {
				return;
			}

			try {

				setLoading('search', true);

				const { results } = await gMap.search(text);

				if (!results || !results[0]) {
					return;
				}

				const point = results[0];
				const geometry = point.geometry;
				const { lat, lng } = geometry.location;

				map.setCenter(
					new google.maps.LatLng(lat, lng)
				);

				const zoom = gMap.getBoundsZoom(
					geometry.bounds,
					{
						width: mapDimensions.width || 0,
						height: mapDimensions.height || 0,
					}
				);

				map.setZoom(zoom);

				setLocation((val) => ({
					...val,
					name: point.formatted_address,
					latitude: lat,
					longitude: lng,
				}));

			} catch (e) {

				console.error(e);

			} finally {

				setLoading('search', false);

			}

		},
		[ map, mapDimensions, setLoading ]
	);

	const onDragEnd = useDebouncedCallback(
		async () => {

			if (!map) {
				return;
			}

			const coords = map.getCenter()?.toJSON();

			if (!coords) {
				return;
			}

			try {

				setLoading('center', true);

				const { results } = await gMap.search(`${coords.lat}, ${coords.lng}`);

				if (!results || !results[0]) {
					return;
				}

				const point = results[0];
				const geometry = point.geometry;
				const { lat, lng } = geometry.location;

				setLocation((val) => ({
					...val,
					name: point.formatted_address,
					latitude: lat,
					longitude: lng,
				}));

			} catch (e) {

				console.error(e);

			} finally {

				setLoading('center', false);

			}

		},
		200
	);

	useEffect(() => {

		if (!map) {
			return;
		}

		onDragEnd();

	}, [ map, onDragEnd ]);

	if (!isLoaded) {
		return null;
	}

	return (
		<div
			ref={ref}
			className={cn('app--map-wrap', { 'map--shown': isVisible })}>
			<div className="map--search">
				<SquareIcon
					icon="arrowBack"
					onClick={onClose} />
				<Search
					loading={loading.search}
					onSearch={onSearch}
					placeholder="Search for city or coordinates" />
			</div>
			<GoogleMap
				zoom={10}
				options={{
					styles: mapStyles,
					backgroundColor: '#181818',
					disableDefaultUI: true,
				}}
				onLoad={setMap}
				center={center}
				onDragEnd={onDragEnd}
				mapContainerStyle={{
					width: '100%',
					height: '100%',
				}}
				mapContainerClassName="map--container">
			</GoogleMap>
			<BottomBar
				title="Locations"
				innerClassName="location-frame">
				<h4>{location.name}</h4>
				{!noRadius && (
				<QuantitySelector
					min={1}
					step={1}
					label="Mile radius"
					amount={location.radius}
					onChange={(radius) => setLocation((val) => ({ ...val, radius }))} />
				)}
				<Button
					label="Save location"
					variant="primary"
					onClick={() => onLocationCreate({ ...location, id: v4(), location_type: locationType || 'personal_appearance' })}
					disabled={!location.name || loading.search || loading.center} />
			</BottomBar>
		</div>
	);

}
