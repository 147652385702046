import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

export const usePushes = (request?: string) => {

	const [ pushes, loading ] = useSelector(
		({ admin, loading }: Store.Root) => [
			admin.pushes,
			loading.effects.admin,
		] as const
	);

	const { admin } = useDispatch<Store.Dispatch>();

	useEffect(() => {

		admin.readPushes(request);

	}, [ admin, request ]);

	return {
		pushes,
		loading,
		...admin,
	};

}
