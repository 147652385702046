export const langManage = {
	pushes: {
		TEXT_LEGEND: 'Fill push',
		DEST_LEGEND: 'Destination',
		DEST_PROMPT: 'Choose how would you like to select users',
		DEST_ROLES: 'Select roles which users will get the notification',
		DEST_CONFIRM: 'You are going to send push notification to',
		ROLE_LEGEND: 'Select by role',
		DEST_CONFIRM_ROLES: 'users with following roles:',
		PLACEHOLDER_NAME: 'Push name',
		PLACEHOLDER_SUBJECT: 'Push subject',
		PLACEHOLDER_MESSAGE: 'Push message',
		SELECT_ROLES: 'Select by roles',
		SELECT_CERTAIN: 'Select certain users',
		SELECT_MIN_USERS: 'Select users first',
		SELECT_MIN_ROLES: 'Select at least one role',
		PUSH_PREVIEW_LEGEND: 'Push preview',
		PUSH_DESTINATION_LEGEND: 'Push destination',
	},
} as const;
