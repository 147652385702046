import { Icon } from '~/components';
import { IconSport } from '~/components/IconSport';

import { asArray } from './arrays';

import { conferences, teams, theme } from '~/constants';
import { Dimensions } from '~/types';

type SurveyStep = 'conference' | 'school' | 'sport' | 'team'

interface IconResolverProps {
	id: string,
	key: SurveyStep,
	size?: Dimensions,
	icon?: boolean,
	iconAlt?: boolean,
	compact?: boolean,
	fontSize?: number | number[],
	originalName?: string,
}

export const iconResolver = (
	props: IconResolverProps
): React.ReactNode | [ string, number ] => {

	const { id, key, size, icon, iconAlt, compact, fontSize, originalName } = props;

	switch (key) {
		case 'team':
		case 'school':
		case 'conference': {

			if (key === 'team' && icon) {
				return <Icon name="Team" />
			}

			const source = key === 'team' ? teams : conferences;

			if (!source[id] && originalName) {
				return (
					<p children={originalName[0]} />
				);
			}

			if (!source[id]) {
				return null;
			}

			const [ name, size, abbr ] = source[id].text;
			const _fontSize = fontSize || size;

			const children = Array.isArray(name) && Array.isArray(_fontSize) ?
				asArray(name).map((val, i) => (
					<p
						key={i}
						style={{ fontSize: iconAlt ? _fontSize[i] / 1.5 : _fontSize[i] }}
						children={val} />
				)) : !Array.isArray(_fontSize) ?
					(<p style={{ fontSize: iconAlt ? _fontSize / 1.5 : _fontSize }} children={name} />) :
					name;

			return (
				<p
					style={{
						maxWidth: compact ? 50 : 300,
						textAlign: 'center',
						fontFamily: theme.font,
						fontWeight: 'bold',
					}}
					children={compact ? abbr : children} />
			);

		}
		case 'sport': {
			return (
				<IconSport
					{...size}
					name={id} />
			);
		}
	}

}
