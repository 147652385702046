import cn from 'classnames';

import { HTMLInput } from '~/types';
import { config } from '~/constants';
import { Loader } from './Loader';
import { ButtonProps } from './Button';

interface ButtonInputProps extends HTMLInput {
	label?: string,
	image?: boolean,
	accept?: string,
	loading?: boolean,
	variant?: ButtonProps['variant'],
	disabledDeep?: boolean,
}

const accepts = {
	image: config.IMAGE_TYPE_LIMIT.map(i => `video/${i}`).join(','),
	video: config.VIDEO_TYPE_LIMIT.map(i => `video/${i}`).join(','),
};

export const ButtonInput: React.FC<ButtonInputProps> = (props) => {

	const {
		label,
		image,
		accept,
		loading,
		variant,
		disabledDeep,
		...input
	} = props;

	const classNames = cn(
		'app-button app--button-input',
		{
			'app-button--loading': loading,
			[`app-button--${variant}`]: variant,
			'app-button--disabled-deep': input.disabled && disabledDeep
		}
	);

	return (
		<div className={classNames}>
			{loading ?
				<Loader loading={loading} /> :
				<>
					<span className="label">{label}</span>
					<input type="file" accept={accept || accepts[image ? 'image' : 'video']} {...input} />
				</>
			}
		</div>
	);

}
