import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { pick } from 'lodash';

import { useAuth } from '~/store';
import { linkRegex } from '~/constants';
import { PageContent, PageTitle } from '~/containers';

import { Button, Input } from '~/components';
import { handleError, socialLinks, SocialLinksForm } from '~/services';

const regex = new RegExp(linkRegex, 'i');

export const Links: React.FC = () => {

	const { goBack } = useHistory();

	const { account, updateSocialLinks } = useAuth();

	const { control, handleSubmit } = useForm<SocialLinksForm>({
		defaultValues: pick(account, Object.keys(socialLinks))
	});

	const submit = async (links: SocialLinksForm) => {

		try {

			await updateSocialLinks(links);

			goBack();

		} catch (e) {

			handleError(e);

		}

	}

	return (
		<>
			<PageTitle
				title="Edit your profile social links"
				onBack={goBack} />
			<PageContent
				pageContentClassName="page--settings">
				<div className="fields">
					{Object.values(socialLinks).map(([ , icon, name ], i) => (
					<Input
						key={i}
						name={name}
						icon={icon}
						rules={{
							validate: (link) => link && !regex.test(link) ?
								'Please enter valid link' :
								true
						}}
						control={control}
						placeholder="Link" />
					))}
				</div>
				<div className="btns">
					<Button
						label="Save"
						onClick={handleSubmit(submit)}
						variant="primary" />
				</div>
			</PageContent>
		</>
	);

}
