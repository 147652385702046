import { Fragment } from 'react';
import { BigAmount, Legend, OptionView } from '~/components';
import { IRequest, IAccount, isAthlete } from '~/services';
import { useAuth } from '~/store';
import { toFixed } from '~/utils';

interface RequestEarningsProps {
	request: IRequest,
	profile: IAccount,
}

export const RequestEarnings: React.FC<RequestEarningsProps> = (props) => {

	const { request, children } = props;

	const { payment } = request;

	const { account } = useAuth();

	const {
		fee_amount,
		request_amount,
		amount_to_athlete,
	} = request;

	const Content: React.ReactNode[] = isAthlete(account) ? [
		<Fragment key={0}>
			<BigAmount
				amount={amount_to_athlete || request_amount} />
			{!!request_amount && <OptionView key={1} large label="Request Amount" value={`$${toFixed(request_amount)}`} />}
			{!!fee_amount && <OptionView key={2} large label="Services Fees" value={`$${toFixed(fee_amount)}`} />}
		</Fragment>
	] : [
		<BigAmount
			key={0}
			amount={request_amount} />
	];

	if (children) {
		Content.push(children);
	}

	return (
		<Legend
			label="Total earnings from this request">
			{Content}
		</Legend>
	);

}
