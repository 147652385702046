import cn from 'classnames';

export interface LegendProps {
	label: string,
	error?: string | boolean,
	className?: string,
	labelBackgroundColor?: `#${string}`,
}

export const Legend: React.FC<LegendProps> = (props) => {

	const { label, error, className, children } = props;

	const classNames = cn(
		'app-legend',
		className,
		{
			multi: Array.isArray(children),
			error: !!error,
		},
	);

	return (
		<div className={classNames}>
			<div className="app-legend--inner">
				<div className="app-legend-head">
					<h4>
						<span>{label}</span>
					</h4>
				</div>
				<div className="app-legend-body">
					{!Array.isArray(children) ? children : children.filter((item) => !!item).map((item, i) => (
					<div key={i} className="app-legend--item">{item}</div>
					))}
				</div>
			</div>
		</div>
	);

}
