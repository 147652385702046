import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { lang } from '~/constants';
import { RequestForm, requestSocialPlatform } from '~/services';

import {
	BigAmount,
	Button,
	CDatePicker,
	Input,
	Legend,
	RSelect
} from '~/components';

export const VideoChat: React.FC<RequestForm.Props> = (props) => {

	const { athlete, onSubmit, processes } = props;

	const { control, handleSubmit, reset } = useForm<RequestForm.VideoChat>();

	const submit = (form: RequestForm.VideoChat) => {

		onSubmit(form, () => reset());

	}

	if (!athlete) {
		return null;
	}

	return (
		<>
			<CDatePicker
				name="date"
				icon="calendar"
				rules={{ required: true }}
				control={control}
				minimumDate={dayjs().add(1, 'minute').toDate()}
				placeholder="Select date" />
			<Input
				name="event_description"
				icon="info"
				rules={{ required: true }}
				control={control}
				textarea
				placeholder="Event description" />
			<Legend
				label={lang.REQUEST_INFO_LABEL}
				children={
					<div className="legend-section">
						<Input
							name="name"
							note={lang.REQUEST_NAME_NOTE}
							rules={{ required: true }}
							control={control}
							placeholder={lang.REQUEST_NAME_PLACEHOLDER} />
						<RSelect
							name="social_media_platform"
							note={lang.REQUEST_PLATFORM_NOTE}
							isMulti
							control={control}
							options={requestSocialPlatform}
							placeholder={lang.REQUEST_PLATFORM_PLACEHOLDER}
							defaultValue={null} />
					</div>
				} />
			<Legend
				label="Total price"
				children={(
					<BigAmount
						amount={athlete.athlete_info.athlete_services.video_chat_rate} />
				)} />
			<Button
				label="Book Now"
				variant="primary"
				onClick={handleSubmit(submit)}
				loading={processes} />
		</>
	);

}
