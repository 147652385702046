import React from 'react';
import { useForm } from 'react-hook-form';

import { UserCreateInput, UserRolesArray } from '~/services';
import { Button, Input, Select } from '~/components';

interface ModalUserCreateProps {
	loading: boolean,
	onHide: () => void,
	onCreate: (form: UserCreateInput) => void
}

export const ModalUserCreate: React.FC<ModalUserCreateProps> = (props) => {

	const { loading, onHide, onCreate } = props;

	const { control, handleSubmit } = useForm<UserCreateInput>();

	return (
		<form onSubmit={handleSubmit(onCreate)}>
			<Input
				icon="mail"
				name="email"
				rules={{ required: true }}
				control={control}
				placeholder="Email" />
			<Select
				icon="user"
				name="role"
				options={UserRolesArray}
				control={control} />
			<div className="actions">
				<Button
					loading={loading}
					variant="primary"
					label="Create" />
				<Button
					type="button"
					onClick={onHide}
					label="Cancel" />
			</div>
		</form>
	);

}
