import Cookies from 'universal-cookie';
import { EventSourcePolyfill as EventSource, EventListener, MessageEvent } from 'event-source-polyfill';

import { config } from '~/constants';
import { INotification, isNotification } from './modules/Notifications';

type SubscribeEvent = {
	type: 'notification',
	payload: INotification
}

type SubscribeCallback = (evt: SubscribeEvent) => void

export const subscribeNotify = (callback: SubscribeCallback) => {

	let source: EventSource | undefined;

	const listener: EventListener = (event) => {

		switch (event.type) {
			case 'message':

				const message = event as MessageEvent;

				if (!message.data) {
					return;
				}

				const payload = JSON.parse(message.data);

				if (isNotification(payload)) {
					callback({ type: 'notification', payload });
				}

				break;

			default:
				// console.log(0, event);
				break;
		}

	}

	const subscribe = async () => {

		const sid = (new Cookies()).get(config.API_COOKIE);

		if (!sid || sid.includes('GUEST')) {
			return;
		}

		source = new EventSource(`${config.URL_BASE}:3000/`, {
			heartbeatTimeout: 180 * 1000,
			headers: {
				[config.API_TOKEN]: sid
			}
		});

		source.addEventListener('open', listener);
		source.addEventListener('error', listener);
		source.addEventListener('message', listener);

	}

	const unsubscribe = () => {

		if (!source) {
			return;
		}

		source.removeEventListener('open', listener);
		source.removeEventListener('error', listener);
		source.removeEventListener('message', listener);
		source.close();

	}

	subscribe();

	return unsubscribe;

}
