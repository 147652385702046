import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';

import { useEffectOnce } from '~/hooks';
import { useNotifications } from '~/store';
import { PageContent, PageTitle } from '~/containers';

import { Notification, Skeleton } from '~/components';
import { INotification, handleError } from '~/services';

export const Notifications: React.FC = () => {

	const { push } = useHistory();

	const { data, loading, setReadAsync, retrieve } = useNotifications();

	useEffectOnce(() => {

		retrieve({ force: true });

	});

	const onClick = useCallback(
		async (item: INotification, e: React.MouseEvent) => {

			const {
				id,
				is_read,
				request_id,
				endorsement_campaign_id: campaign
			} = item;

			try {

				if (!is_read) {
					await setReadAsync(id);
				}

				if (e.altKey) {
					return;
				}

				if (!validate(request_id)) {
					return;
				}

				push(`/request/${campaign ? `@${campaign}` : request_id}`);

			} catch (e) {

				handleError(e);

			}

		},
		[]
	);

	const renderItem = (notification: INotification) => (
		<Notification
			key={notification.id}
			onClick={onClick}
			notification={notification} />
	);

	const EmptyComponent = (
		<p className="content--empty" children={`You don't have any\n notifications yet`} />
	);

	return (
		<>
			<PageTitle
				title="Notifications" />
			<PageContent
				data={data}
				loading={loading.retrieve}
				renderItem={renderItem}
				loadingComponent={<Skeleton type="notifications" />}
				pageEmptyComponent={EmptyComponent} />
		</>
	);

}
