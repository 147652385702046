import moment from 'moment';
import { Controller } from 'react-hook-form';

import { ControlledProps } from '../types';
import { DatePicker, DatePickerProps } from './DatePicker';

export type CDatePickerProps = DatePickerProps & ControlledProps;

export const CDatePicker: React.FC<CDatePickerProps> = (props) => {

	const { name, rules = {}, control, ...args } = props;

	if (!rules?.validate) {
		rules.validate = (val) => moment(val).isValid();
	}

	if (args.minimumDate) {
		rules.validate = (val) => {
			const now = moment(val);
			return now.isValid() && now.isAfter(args.minimumDate);
		}
	}

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			render={({ field, fieldState }) => {
				return (
					<DatePicker
						state={fieldState}
						value={field.value}
						innerRef={field.ref}
						onChange={field.onChange}
						{...args} />
				);
			}} />
	);

}
