import { useCallback, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { toast } from 'react-toastify';

import { usePushes } from '~/store';
import { SearchParam } from '~/utils';

import { Button, Search, SquareIcon, useOverlay } from '~/components';
import { CustomPush, CustomPushInput, CustomPushSend, confirm, _confirm } from '~/services';

import { ModalPushCreate, ModalPushSend } from '~/containers/manage';

export const Pushes: React.FC = () => {

	const [ isUpdated, setIsUpdated ] = useState(false);

	const [ request, setRequest ] = useQueryParam('request', SearchParam);

	const {
		pushes,
		loading,
		sendPush,
		createPush,
		updatePush,
		deletePush
	} = usePushes(request);

	const { Over, OverItem, ...over } = useOverlay({
		push: {
			title: 'Manage push',
			className: 'create create-push'
		},
		send: {
			className: 'send-push'
		},
	}, async (key) => {

		if (key !== 'send' || !isUpdated) {
			return true;
		}

		const confirmed = await _confirm.editLeave();

		if (confirmed) {
			setIsUpdated(false);
		}

		return confirmed;

	});

	const create = useCallback(
		(data: CustomPushInput, id?: string) => {

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await (id ?
						updatePush({ id, ...data }) :
						createPush(data)
					);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

					over.hide('push');

				}),
				{
					pending: `${id ? 'Updating' : 'Creating new'} custom push`,
					success: `Custom push has been ${id ? 'updated' : 'created'}`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ createPush, updatePush, over ]
	);

	const remove = useCallback(
		async (id: string) => {

			if (!await _confirm.unitRemove('push')) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await deletePush(id);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: 'Deleting custom push',
					success: 'Custom push has been deleted',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ deletePush ]
	);

	const onSend = useCallback(
		async (data: CustomPushSend) => {

			if (!await confirm({
				title: 'Confirm action',
				message: 'Are you sure want to send these pushes?',
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await sendPush(data);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

					setIsUpdated(false);

				}),
				{
					pending: 'Sending pushes',
					success: 'Pushes has been sent',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ sendPush ]
	);

	return (
		<div className="manage-content">
			<div className="page-header">
				<h1>Custom pushes</h1>
				<div className="page-controls">
					<Button
						variant="secondary"
						label="Send"
						onClick={() => over.show('send')} />
					<Button
						variant="primary"
						label="Add"
						onClick={() => over.show('push')} />
					<Search
						loading={loading.readPushes}
						onSearch={setRequest}
						placeholder="Search for pushes"
						defaultValue={request} />
				</div>
			</div>
			<div className="users-list">
				{pushes.map((push) => (
				<div
					key={push.id}
					className="users-list--item">
					<SquareIcon icon="mail" />
					<div className="user-content">
						<h6 className="push-name">{push.name}</h6>
						<h4>{push.subject}</h4>
						<p>{push.message}</p>
					</div>
					<div className="user-actions">
						<Button
							label="Send"
							onClick={() => over.show('send', push)} />
						<Button
							label="Update"
							onClick={() => over.show('push', push)} />
						<Button
							label="Remove"
							onClick={() => remove(push.id)} />
					</div>
				</div>
				))}
			</div>
			<Over {...over}>
				<OverItem
					{...over}
					name="send"
					render={(payload) => (
						<ModalPushSend
							push={payload as CustomPush}
							update={[ isUpdated, setIsUpdated ]}
							onSend={onSend}
							onHide={() => over.hide('send')} />
					)} />
				<OverItem
					{...over}
					name="push"
					render={(payload) => (
						<ModalPushCreate
							push={payload as CustomPush}
							onHide={() => over.hide('push')}
							loading={loading.createPush}
							onCreate={create} />
					)} />
			</Over>
		</div>
	);

}
