import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flag, Grid, Search } from '~/components';

import { lang } from '~/constants';
import { useData } from '~/store';
import { iconResolver, pushOrRemove } from '~/utils';

import { StepProps, SurveyType } from '~/services/signup';

export const SignUpSurvey: React.FC<StepProps> = (props) => {

	const {
		step: [ , setStep ],
		data: [ data, setData ],
		steps,
		account
	} = props;

	const refData = useData();

	useEffect(() => {

		if (!refData.available_schools.length) {
			return;
		}

		return setData((data) => ({
			...data,
			school: [ refData.available_schools[0] ]
		}));

	}, [ refData.available_schools, setData ]);

	const [ search, setSearch ] = useState('');

	const filteredRefData = useMemo(() => {

		const _data = refData.available_schools;

		if (!search) {
			return _data;
		}

		return _data.filter(
			({ name }) => name
				.toLowerCase()
				.includes(search.toLowerCase())
		);

	}, [ refData, search ]);

	const toggleDataItem = useCallback(
		<T extends SurveyType.Item>(step: SurveyType.Step, value: T) => {

			setData((data) => ({
				...data,
				[step]:
					account?.user_type === 12 ?
					[ value ] :
					pushOrRemove(data[step], value),
			}));

		},
		[]
	);

	const isDataItemPicked = useCallback(
		<T extends SurveyType.Item>(data: T[], value: T): boolean => {

			return !!data.filter(({ id }) => id === value.id)[0];

		},
		[]
	);

	const proceed = () => {

		setSearch('');

		steps.next && setStep(steps.next);

	}

	return (
		<>
			<h2 className="less-pad">{lang.SIGNUP_SURVEY_SPORT_TITLE}</h2>
			<p>{lang.SIGNUP_SURVEY_SPORT_KICKER}</p>
			<Grid
				content={refData.sports}
				rowCount={5}
				children={(sport) => {
					const onClick = () => toggleDataItem('sport', sport);
					const isSelected = isDataItemPicked(data.sport, sport);
					return (
						<Flag
							key={sport.id}
							label={sport.name}
							onClick={onClick}
							className="app--grid-item"
							isSelected={isSelected}>
							<i
								children={iconResolver({
									id: sport.id,
									key: 'sport',
									icon: true,
								})} />
						</Flag>
					);
				}}
				className="survey" />
			{refData.available_schools.length > 1 &&
			<>
				<h2 className="less-pad">{lang.SIGNUP_SURVEY_SCHOOL_TITLE}</h2>
				<p>{lang.SIGNUP_SURVEY_SPORT_KICKER}</p>
				<Search
					value={search}
					onSearch={setSearch}
					placeholder={lang.SIGNUP_SURVEY_SCHOOL_SEARCH} />
				<Grid
					content={filteredRefData}
					rowCount={5}
					children={(school) => {
						const onClick = () => toggleDataItem('school', school);
						const isSelected = isDataItemPicked(data.school, school);
						return (
							<Flag
								key={school.id}
								label={school.name}
								onClick={onClick}
								className="app--grid-item"
								isSelected={isSelected}>
								<i
									children={iconResolver({
										id: school.id,
										key: 'school',
										icon: true,
										originalName: school.name,
									})} />
							</Flag>
						);
					}}
					className="survey" />
			</>
			}
			<div className="proceed-btn">
				<Button
					label="Submit"
					variant="primary"
					onClick={proceed}
					disabled={!data.sport.length || (refData.available_schools.length > 1 && !data.school.length)}
					disabledDeep />
			</div>
		</>
	);

}
