import { useCallback, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { StripeError } from '@stripe/stripe-js';

import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useData } from '~/store';
import { PaymentSheet } from '~/services';
import { Button, Loader, Modal } from '~/components';
import { theme } from '~/constants';

interface StripeModalProps {
	redirect: string,
	isVisible: boolean,
	paymentSheet: PaymentSheet | null,
	onHide: () => void
}

type StripeOptions = Parameters<typeof Elements>[0]['options'];

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const options: (payment: PaymentSheet) => StripeOptions = ({ payment_intent }) => ({
	clientSecret: payment_intent.client_secret,
	appearance: {
		variables: {
			colorText: '#fff',
      colorPrimary: '#cc6202',
			fontFamily: `'${theme.font}', sans-serif`,
			borderRadius: '3px',
			focusOutline: 'red',
			focusBoxShadow: '0 0 0 transparent',
			colorBackground: '#222',
			colorBackgroundText: 'rgba(255, 255, 255, .15)',
		},
	},
});

const StripeCheckout: React.FC<{ redirect: string }> = ({ redirect }) => {

	const stripe = useStripe();

	const elements = useElements();

	const [ paying, setPaying ] = useState(false);

	const [ ready, setReady ] = useState(false);

	const [ error, setError ] = useState<StripeError | null>(null);

	const pay = useCallback(
		async () => {

			if (!stripe || !elements) {
				return;
			}

			setPaying(true);

			const result = await stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: redirect,
				},
			});

			if (!result.error) {
				return;
			}

			setError(result.error);
			setPaying(false);

		},
		[ stripe, elements, redirect ]
	);

	return (
		<>
			{/* <p>In order to purchase a shoutout, we’ll continue the payment using stripe.</p> */}
			<PaymentElement
				onReady={() => setReady(true)} />
			{error && <p className="stripe-error">{error.message}</p>}
			{ready ? (
			<Button
				label="Checkout"
				variant="primary"
				onClick={pay}
				loading={paying || !stripe || !elements} />
			) : (
			<Loader
				loading
				variant="absolute" />
			)}
		</>
	);

}

export const StripeModal: React.FC<StripeModalProps> = (props) => {

	const { redirect, isVisible, paymentSheet, onHide } = props;

	const { stripe_public: stripeKey } = useData();

	const stripe = useMemo(
		() => stripeKey ? loadStripe(stripeKey) : null,
		[ stripeKey ]
	);

	if (!stripeKey) {
		return null;
	}

	return (
		<Modal
			title="Checkout"
			icon="payment"
			onHide={onHide}
			visible={isVisible}
			className="stripe--modal">
			{paymentSheet && (
			<Elements
				stripe={stripe}
				options={options(paymentSheet)}>
				<StripeCheckout
					redirect={redirect} />
			</Elements>
			)}
		</Modal>
	);

}
