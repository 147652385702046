import { useMemo, useState } from 'react';

import { useUsers } from '~/store';
import { StateResponse } from '~/types';
import { _isInArray, _pushOrRemove } from '~/utils';

import { Pages, Search, Select } from '~/components';
import { IAccount, UserRolesArray, UsersReadInput, UserType } from '~/services';

import { PushUser } from './PushCompactUser';

interface PushCertainUsersProps {
	users: StateResponse<IAccount[]>
}

export const PushCertainUsers: React.FC<PushCertainUsersProps> = (props) => {

	const { users: data } = props;

	const [ selected, setSelected ] = data;

	const nodeRef = useMemo<HTMLDivElement | null>(
		() => document.querySelector('.app-over-item'),
		[]
	);

	const [ params, setParams ] = useState<UsersReadInput>({
		page: 0,
		request: '',
		page_size: 20,
		user_type: undefined,
		profile_completed: 1,
	});

	const { users, loading } = useUsers(params);

	return (
		<>
			<Search
				loading={loading.readUsers}
				onReset={() => setParams(p => ({ ...p, page: 0, request: '' }))}
				onSearch={(request) => setParams(p => ({ ...p, page: 0, request }))}
				placeholder="Search for users" />
			<div className="filters">
				<Select
					compact
					options={[
						{ label: 'Any type', value: undefined },
						...UserRolesArray
					]}
					onChange={({ value }) => {
						setParams(val => ({
							...val,
							page: 0,
							user_type: value as UserType | undefined,
						}));
					}}
					value={params.user_type}
					defaultValue={params.user_type} />
				<Select
					compact
					options={[
						{ label: 'Any status', value: 2 },
						{ label: 'Only completed', value: 1 },
						{ label: 'Only incomplete', value: 0 },
					]}
					onChange={({ value }) => {
						setParams(val => ({
							...val,
							page: 0,
							profile_completed: value as 0 | 1 | 2 | undefined,
						}));
					}}
					value={params.profile_completed}
					defaultValue={params.profile_completed} />
			</div>
			{users.users.map((account) => (
			<PushUser
				key={account.id}
				account={account}
				onSelect={() => setSelected(_pushOrRemove(selected, account))}
				isSelected={_isInArray(selected, account)}
				isSelectable />
			))}
			{!!(users.users.length || params.page) && (
			<Pages
				compact
				forcePage={params.page}
				pageCount={users.total_pages}
				onPageChange={({ selected: page }) => {

					setParams(val => ({ ...val, page }));

					nodeRef?.scrollTo(0, 0);

				}} />
			)}
		</>
	);

}
