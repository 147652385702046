import { asArray, iconResolver } from '~/utils';

const icons = ['sport'] as const;

interface AthleteLabelsProps {
	sports: string,
}

export const AthleteLabels: React.FC<AthleteLabelsProps> = (props) => {

	const { sports } = props;

	const ids = asArray(sports, (str) => str.split(','));

	return (
		<div className="app--athlete-labels">
			{ids.map((id, i) => (
			<div
				key={i}
				children={ids && iconResolver({
					id: id,
					key: 'sport',
					compact: true,
					fontSize: 13,
				})} />
			))}
		</div>
	);

}
