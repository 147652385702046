import { useState } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import cn from 'classnames';

import { ControlledInner, PlainOrMerge, getFieldState } from '~/types';
import { asIcon } from '~/utils';

import { Icon, IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';

type Mode = 'date' | 'time' | 'datetime'

// const modeFormat: Record<Mode, string> = {
// 	date: 'ddd D MMM, YYYY',
// 	time: 'h:mm A',
// 	datetime: 'ddd MMM D h:mm A, YYYY',
// };

export interface DatePickerProps {
	icon?: IconArgs,
	mode?: Mode,
	value?: Date,
	innerRef?: React.LegacyRef<HTMLInputElement>,
	minimumDate?: Date,
	placeholder?: string,
	onChange?: (date: Date) => void
}

export const DatePicker: React.FC<
	PlainOrMerge<DatePickerProps, ControlledInner>
> = (props) => {

	const {
		icon,
		mode = 'datetime',
		value: initialValue,
		innerRef,
		minimumDate,
		placeholder = 'Select date',
		onChange,
		...args
	} = props;

	const state = getFieldState(args);

	const [ value ] = useState<Date>();

	const _icon = asIcon(
		icon || mode === 'date' ? 'calendar' : 'clock',
	);

	return (
		<div className={cn('app--date-picker', { invalid: state && !!state.error })}>
			{_icon && (
			<SquareIcon
				icon={_icon} />
			)}
			<div className="app--date-picker-container">
				<div className="app--date-picker-element">
					<Datetime
						value={value}
						onChange={(val) => onChange && onChange(moment(val).toDate())}
						dateFormat="ddd MMM D YYYY"
						timeFormat="h:mm A"
						isValidDate={(val) => {
							const now = moment(val);
							if (!minimumDate) {
								return now.isValid();
							}
							return now.isValid() &&
								now.isAfter(
									moment(minimumDate).subtract(1, 'd')
								);
						}}
						inputProps={{ placeholder, ref: innerRef }} />
					<Icon
						name="downChevron" />
				</div>
				{state && !!state.error?.message && (
				<p children={state.error.message} />
				)}
			</div>
		</div>
	);

}
