import { useForm } from 'react-hook-form';

import { langManage } from '~/constants';
import { CustomPush, CustomPushInput } from '~/services';
import { Button, Input } from '~/components';

interface ModalPushCreateProps {
	push?: CustomPush,
	loading: boolean,
	onHide: () => void,
	onCreate: (form: CustomPushInput, id?: string) => void
}

export const ModalPushCreate: React.FC<ModalPushCreateProps> = (props) => {

	const { push, loading, onHide, onCreate } = props;

	const { control, handleSubmit } = useForm<CustomPushInput>({
		defaultValues: {
			name: push?.name,
			message: push?.message,
			subject: push?.subject,
		},
	});

	const submit = (data: CustomPushInput) => {
		onCreate(data, push?.id);
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Input
				icon="description"
				name="name"
				rules={{ required: true }}
				control={control}
				placeholder={langManage.pushes.PLACEHOLDER_NAME} />
			<Input
				icon="bell"
				name="subject"
				rules={{ required: true }}
				control={control}
				placeholder={langManage.pushes.PLACEHOLDER_SUBJECT} />
			<Input
				icon="mail"
				name="message"
				rules={{ required: true }}
				control={control}
				textarea
				placeholder={langManage.pushes.PLACEHOLDER_MESSAGE} />
			<div className="actions">
				<Button
					loading={loading}
					variant="primary"
					label={push ? 'Update' : 'Create'} />
				<Button
					type="button"
					onClick={onHide}
					label="Cancel" />
			</div>
		</form>
	);

}
