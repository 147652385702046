import { useCallback, useEffect, useState } from 'react';
import { useQueryParams } from 'use-query-params';

import { OptionParam, SearchParam } from '~/utils';

import { handleError } from './api';

import {
	Athlete,
	IAthlete,
	SEARCH_FIELDS_OPTIONS,
	SEARCH_PLACEHOLDER,
} from './modules';

export function useAthleteSearch (campaign?: boolean) {

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const [ loading, setLoading ] = useState(0);

	const [ { filter, field: _field }, setParams ] = useQueryParams({
		filter: SearchParam,
		field: OptionParam(SEARCH_FIELDS_OPTIONS, 'name'),
	});

	const [ field, setField ] = useState('name');

	useEffect(
		() => {
			filter && setField(_field || 'name');
		},
		[ filter, _field ]
	);

	const retrieve = useCallback(async () => {

		try {

			setLoading(filter ? 2 : 1);

			const { athletes } = await Athlete[campaign ? 'readEndorsement' : 'read']({ filter, field }).promise;

			setAthletes([]);

			setAthletes(athletes);

		} catch (e) {

			handleError(e);

		} finally {

			setLoading(0);

		}

	}, [ filter, field, campaign ]);

	useEffect(() => {

		retrieve();

	}, [ retrieve ]);

	const trigger = useCallback(
		(key?: 'filter' | 'field', value?: string) => {

			if (!key) {
				return setParams({ filter: undefined, field: undefined, });
			}

			setParams(v => ({ ...v, [key]: value }));

		},
		[ setParams ]
	);

	return {
		athletes,
		loading,
		filter,
		field: _field,
		trigger,
		text: {
			SEARCH_FIELDS_OPTIONS,
			SEARCH_PLACEHOLDER,
		},
	};

}
