import { createModel } from '@rematch/core';
import Cookies from 'universal-cookie';
import { v4 } from 'uuid';

import { Store } from './';

import { Account, handleError, IAccount, SignInInput, SignUpAthleteInputMid, SocialLinksForm, SocialLinksKeys, UpdateInput } from '~/services';
import { config } from '~/constants';

export enum Status {
	authorized = 1,
	authorizing = 0,
	unauthorized = -1
}

interface AuthState {
	status: Status,
	isGuest?: boolean,
	account?: IAccount
}

const cookies = new Cookies();

const state: AuthState = {
	status: Status.authorizing,
};

export const auth = createModel<Store.General>()({
	state,
	reducers: {
		_authorize: (_, account?: IAccount, isGuest?: boolean) => {
			return {
				status: Status.authorized,
				account: account ? {
					...account,
					user_type: account?.profile_completed ?
						(account.user_type) :
						(account.user_type + 10) as 11 | 12 | 13,
				} : undefined,
				isGuest,
			};
		},
		_unAuthorize: () => {
			return {
				status: Status.unauthorized,
			};
		},
		updatePayment: (state) => {

			return {
				...state,
				account: !state.account ? undefined : {
					...state.account,
					payment_source: true,
				},
			};

		},
		_updateAccount: (state, data: UpdateInput) => {

			if (!state.account) {
				return state;
			}

			return {
				...state,
				account: {
					...state.account,
					...data
				},
			};

		},
		_updateLinks: (state, links: SocialLinksForm | SocialLinksKeys) => {

			if (!state.account) {
				return state;
			}

			return {
				...state,
				account: {
					...state.account,
					...(typeof links === 'string' ? {
						[links]: '',
					} : {
						...links,
					}),
				},
			};

		},
		_updateMidSignUp: (state, data: SignUpAthleteInputMid) => {

			if (!state.account) {
				return state;
			}

			return {
				...state,
				account: {
					...state.account,
					...data,
				},
			};

		},
		_deleteAccount: (state) => {

			return state;

		},
	},
	effects: (dispatch) => ({
		signIn: async (input: SignInInput) => {

			const { account, session_token } = await Account.signIn(input).promise;

			cookies.set(config.API_COOKIE, session_token, { path: '/' });

			dispatch.auth._authorize(account);

		},
		retrieve: async (token?: string) => {

			if (token) {
				cookies.set(config.API_COOKIE, token, { path: '/' });
			}

			try {

				const currentToken = cookies.get(config.API_COOKIE);

				if (!currentToken) {
					dispatch.auth._unAuthorize();
					return;
				} else if (currentToken.includes('GUEST-')) {
					dispatch.auth._authorize(undefined, true);
					return;
				}

				const { account } = await Account.read().promise;

				dispatch.auth._authorize(account);

			} catch (error) {

				dispatch.auth._unAuthorize();

			}

		},
		authorize: async (args: { account: IAccount, token?: string }) => {

			const { token, account } = args;

			if (token) {
				cookies.set(config.API_COOKIE, token, { path: '/' });
			}

			dispatch.auth._authorize(account);

		},
		unAuthorize: async (isGuest?: boolean) => {

			try {

				if (!isGuest) {
					await Account.signOut().promise;
				}

				cookies.remove(config.API_COOKIE);

				dispatch.admin._clear();
				dispatch.notify.clear();
				dispatch.athletes.clear();
				dispatch.requests.clear();

				dispatch.auth._unAuthorize();

			} catch (e) {}

		},
		authorizeGuest: async () => {

			cookies.set(config.API_COOKIE, `GUEST-${v4()}`, { path: '/' });

			dispatch.auth._authorize(undefined, true);

		},
		removeSocialLink: async (link: SocialLinksKeys, state) => {

			if (!state.auth.account) {
				return;
			}

			try {

				await Account.update({
					...state.auth.account,
					[link]: '',
				}).promise;

				dispatch.auth._updateLinks(link);

			} catch (e) {

				handleError(e);

			}

		},
		updateSocialLinks: async (links: SocialLinksForm, state) => {

			if (!state.auth.account) {
				return;
			}

			try {

				await Account.update({
					...state.auth.account,
					...links,
				}).promise;

				dispatch.auth._updateLinks(links);

			} catch (e) {

				handleError(e);

			}

		},
		updateAccount: async (data: UpdateInput, state) => {

			await Account.update({
				...state.auth.account,
				...data
			}).promise;

			dispatch.auth._updateAccount({
				...state.auth.account,
				...data
			});

		},
		updateMidAuth: async (form: SignUpAthleteInputMid) => {

			await Account.signUpAthleteMid(form).promise;

			dispatch.auth._updateMidSignUp(form);

		},
		deleteAccount: async () => {

			await Account.delete().promise;

			dispatch.auth._deleteAccount();

		},
	}),
});
