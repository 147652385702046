import cn from 'classnames';

import { asIcon } from '~/utils';
import { ControlledInner, PlainOrMerge, getFieldState, HTMLButton } from '~/types';

import { Icon, IconArgs } from './Icon';
import { SquareIcon } from './SquareIcon';

export interface InputButtonProps extends Omit<HTMLButton, 'value'> {
	icon?: IconArgs,
	value?: unknown,
	placeholder?: string,
	onChange?: (value: unknown) => void,
	titleTransform?: (value: unknown) => string
}

export const InputButton: React.FC<
	PlainOrMerge<InputButtonProps, ControlledInner>
> = (props) => {

	const {
		icon,
		value,
		placeholder,
		titleTransform,
		...args
	} = props;

	const _icon = asIcon(icon);

	const state = getFieldState(args);

	return (
		<div className={cn('app--input-button', { invalid: state && !!state.error })}>
			{_icon && (
				<SquareIcon icon={_icon} />
			)}
			<div className="app--input-button-element">
				<button {...args}>
					<p children={(!!(value && titleTransform) && titleTransform(value)) || placeholder} />
					<Icon
						name="downChevron" />
				</button>
				{state && !!state.error?.message && (
				<p children={state.error.message} />
				)}
			</div>
		</div>
	);

}
