import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Agora from 'agora-rtc-react';

import { useLoading } from '~/hooks';

import {
	handleError,
	REQUEST_TYPE,
	RequestActionsProps,
	isRequest,
	_confirm,
} from '~/services';

import {
	Button,
	VideoButton,
	Legend,
	Modal,
	VideoWrap
} from '~/components';

type VideoType = {
	file?: File,
	error?: string,
	modal?: boolean
}

export const RequestActionsAthlete: React.FC<RequestActionsProps> = (props) => {

	const {
		request: { profile, ...request },
		onUpdate
	} = props;

	const { goBack, push } = useHistory();

	const { status } = request;

	const [ loading, setLoading ] = useLoading<'accept' | 'decline' | 'upload'>();

	const [ video, setVideo ] = useState<VideoType>({});

	const [ hasCamera, setHasCamera ] = useState(false);

	useEffect(() => {

		if (request.type !== 'video_chat') {
			return;
		}

		const getCameras = async () => {

			const cameras = await Agora.getCameras();

			setHasCamera(!!cameras.length);

		}

		getCameras();

	}, [ request.type ]);

	const onAccept = useCallback(async () => {

		try {

			setLoading('accept', true);

			await onUpdate({ id: request.id, type: 'ACCEPT_REQUEST', });

		} catch (e) {

			handleError(e);

		} finally {

			setLoading('accept', false);

		}

	}, [ setLoading, request, onUpdate ]);

	const onDecline = useCallback(async () => {

		try {

			if (!await _confirm.requestDecline()) {
				return;
			}

			setLoading('decline', true);

			await onUpdate({ id: request.id, type: 'DECLINE_REQUEST', });

			goBack();

		} catch (e) {

			handleError(e);

		}

	}, [ setLoading, goBack, request, onUpdate ]);

	const onSubmit = useCallback(async () => {

		try {

			setLoading('upload', true);

			const { file } = video;

			if (!file) {
				return;
			}

			await onUpdate({
				id: request.id,
				type: 'VIDEO_UPLOAD',
				request_type: request.type,
				file,
			});

			// TODO:
			// Alert.alert(
			// 	'Your video has been uploaded successfully',
			// 	undefined,
			// 	[{
			// 		text: 'OK',
			// 		onPress: () => push(`/requests`),
			// 	}]
			// );

		} catch (e) {

			handleError(e);

		}

	}, [ setLoading, onUpdate, request, video ]);

	const VideoModal = (
		<Modal
			icon="videoBig"
			title={`Record Your ${request.type}`}
			onHide={() => setVideo((val) => ({ ...val, modal: undefined }))}
			visible={!!video.modal}
			className="video-record--modal"
			children={(
				<>
					<p children={`Click below to upload your ${request.type} video`} />
					<VideoButton
						label="Upload video"
						error={video.error}
						noLegend
						onError={(error) => setVideo((val) => ({ ...val, error }))}
						onVideoChange={(file) => setVideo({ file: file as File, modal: false })}
						limitDuration={99999} />
					<Button
						label="Cancel"
						onClick={() => setVideo((val) => ({ ...val, modal: undefined }))} />
				</>
			)} />
	);

	if (status === 1) {
		return (
			<>
				<Button
					variant="primary"
					loading={loading.accept}
					onClick={onAccept}
					label="Accept" />
				<Button
					variant="secondary"
					loading={loading.decline}
					onClick={onDecline}
					label="Decline" />
			</>
		);
	}

	if (status === 2 && isRequest.withVideo(request)) {
		if (request.is_apple_iap && !request.paid) {
			return (
				<Legend
					label={`Record Your ${request.type}`}
					children={<Button label="Awaiting payment" />}/>
			);
		}
		return (
			<>
				{VideoModal}
				<Legend
					label={`Record Your ${request.type}`}
					children={(
						<>
							{!!video.file && (
							<VideoWrap
								controls
								source={{ uri: video.file }} />
							)}
							<Button
								loading={loading.upload}
								variant="secondary"
								onClick={() => setVideo({ modal: true })}
								label={video.file ? 'Replace video' : 'Upload / Record Now'} />
							<Button
								onClick={() => video.file && onSubmit()}
								loading={loading.upload}
								disabled={!video.file}
								disabledDeep
								variant="primary"
								label={`Send ${request.type}`} />
						</>
					)} />
			</>
		);
	}

	if (status === 2 && isRequest.withTimestamp(request)) {
		return (
			<>
				{/* <Button
					variant="primary"
					label="Add to calendar"
					onClick={() => {}} /> */}
				<Button
					label={`Awaiting ${REQUEST_TYPE[request.type][0]}`} />
			</>
		);
	}

	if (status === 6 && isRequest.videoChat(request) && request.video_chat.ready) {
		if (!hasCamera) {
			return (
				<Button
					label="Camera is not recognized" />
			);
		}
		return (
			<Button
				variant="primary"
				label="Join chat"
				onClick={() => push(`/chat/${request.id}`)} />
		);
	}

	return null;

}
