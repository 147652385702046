import cn from 'classnames';
import { SquareIcon } from '~/components';
import { useAppState } from '~/store';

interface PageTitleProps {
	title: string,
	onBack?: () => void
}

export const PageTitle: React.FC<PageTitleProps> = (props) => {

	const { title, onBack } = props;

	const [ , setMenuVisibility ] = useAppState();

	return (
		<div className={cn('app--page-title', { 'with-back': !!onBack })}>
			{!!onBack && (
			<SquareIcon
				icon="arrowBack"
				onClick={onBack} />
			)}
			<h1>{title}</h1>
			<button
				onClick={() => setMenuVisibility(true)}
				className="menu">
				<i></i>
			</button>
		</div>
	);

}
