import { api } from '../api';

import { IRequest, RequestType } from './Request';

export type Address = {
	city: string,
	line1: string,
	line2: string,
	state: string,
	country: string,
	postal_code: string
}

export type Customer = {
	address: Address,
	balance: number,
	created: number,
	currency: string,
	default_source: string,
	deleted: boolean,
	delinquent: boolean,
	description: string,
	discount: null,
	email: string,
	id: string,
	invoice_prefix: string,
	invoice_settings: {
		custom_fields: null,
		default_payment_method: null,
		footer: string,
	},
	livemode: boolean,
	metadata: {
		customer_id: string,
		user_type: string,
	},
	name: string,
	next_invoice_sequence: number,
	object: string,
	phone: string,
	preferred_locales: [],
	shipping: null,
	sources: null,
	subscriptions: null,
	tax: null,
	tax_exempt: string,
	tax_ids: null
}

export type EphemeralKey = {
	id: string,
	object: string,
	secret: string,
	created: number,
	expires: number,
	livemode: boolean,
	associated_objects: {
		id: string,
		type: string
	}[]
}

export type PaymentIntent = {
	amount: number,
	amount_capturable: number,
	amount_received: number,
	application: null,
	application_fee_amount: number,
	canceled_at: number,
	cancellation_reason: string,
	capture_method: string,
	charges: {
		has_more: boolean,
		url: string,
		total_count: number,
		data: []
	},
	client_secret: string,
	confirmation_method: string,
	created: number,
	currency: string,
	customer: {
		address: Address,
		balance: number,
		created: number,
		currency: string,
		default_source: null,
		deleted: boolean,
		delinquent: boolean,
		description: string,
		discount: null,
		email: string,
		id: string,
		invoice_prefix: string,
		invoice_settings: null,
		livemode: boolean,
		metadata: null,
		name: string,
		next_invoice_sequence: number,
		object: string,
		phone: string,
		preferred_locales: null,
		shipping: null,
		sources: null,
		subscriptions: null,
		tax: null,
		tax_exempt: string,
		tax_ids: null
	},
	description: string,
	invoice: null,
	last_payment_error: null,
	livemode: boolean,
	id: string,
	metadata: {},
	next_action: null,
	on_behalf_of: null,
	payment_method: null,
	payment_method_options: {
		acss_debit: null,
		afterpay_clearpay: null,
		alipay: null,
		bancontact: null,
		boleto: null,
		card: {
			installments: null,
			network: string,
			request_three_d_secure: string,
		},
		oxxo: null,
		sofort: null,
		wechat_pay: null
	},
	payment_method_types: ('card')[],
	receipt_email: string,
	review: null,
	setup_future_usage: string,
	shipping: {
		address: null,
		carrier: string,
		name: string,
		phone: string,
		tracking_number: string,
	},
	source: null,
	statement_descriptor: string,
	statement_descriptor_suffix: string,
	status: string,
	transfer_data: null,
	transfer_group: string
}

export interface PaymentSheet {
	customer: Customer,
	ephemeralKey: EphemeralKey,
	payment_intent: PaymentIntent
}

export const Stripe = {

	setPaid: (id: string) => api.post<{
		request: IRequest
	}>('/stripe/set-paid', { request_id: id }, { type: 'FORM' }),

	paymentSheet: {
		get: (
			id: string,
			type: RequestType
		) => api.post<PaymentSheet>(
			`/stripe/payment-sheet?${
				type === 'endorsement_campaign' ? 'endorsement_campaign_id' : 'request_id'
			}=${id}`
		),
	},

	paymendMethod: {
		add: (data: { card_token: string }) => api.post('/stripe/payment-method', data, { type: 'DATA' }),
		get: () => api.get('/stripe/payment-method'),
	},

};
