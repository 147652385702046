import { Button, Icon, Logo, SquareIcon } from '~/components';

import { useAuth } from '~/store';

import { _confirm } from '~/services';
import { SignUpFormProps } from '~/services/signup';

export const SignUpWrap: React.FC<SignUpFormProps> = (props) => {

	const {
		step: [ , setStep ],
		steps: { title, prev },
		children
	} = props;

	const { unAuthorize } = useAuth();

	const backOrLogout = async () => {

		if (prev) {
			return setStep(prev);
		}

		if (!await _confirm.logout()) {
			return;
		}

		unAuthorize();

	}

	return (
		<div className="sign-up--wrap">
			<div className="sign-up--side">
				<div className="sign-up--side-inner">
					<div className="sign-up--side-container">
						<Logo />
						<Button
							label="Log Out"
							onClick={() => unAuthorize()} />
					</div>
				</div>
			</div>
			<div className="sign-up--step">
				<div className="sign-up--step-inner">
					<div className="sign-up--title">
						<SquareIcon
							icon="arrowBack"
							onClick={backOrLogout} />
						<h1>{title}</h1>
					</div>
					<div className="sign-up--step-container">
						{children}
					</div>
				</div>
			</div>
		</div>
	);

}
