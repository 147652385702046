import { MouseEvent } from 'react';
import cn from 'classnames';

import { INotification } from '~/services';
import { getRelativeTime } from '~/utils';

interface NotificationProps {
	onClick: (notification: INotification, e: MouseEvent) => void
	notification: INotification,
}

export const Notification: React.FC<NotificationProps> = (props) => {

	const { notification, onClick } = props;

	return (
		<div
			onClick={(e) => onClick(notification, e)}
			className={cn('app--notification', { 'is-read': notification.is_read })}>
			<div className="app--notification-inner">
				<h4>{notification.data}</h4>
				<p>{getRelativeTime(notification.created_at)}</p>
				{!notification.is_read && <div className="read-indictor" />}
			</div>
		</div>
	);

}
