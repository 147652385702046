import { useEffect } from 'react';
import { NavLink, useLocation, matchPath, NavLinkProps, Link } from 'react-router-dom';
import cn from 'classnames';

import { Icon, IconProps, SquareIcon, Logo } from '~/components';
import { useAppState, useAuth, useNotifications } from '~/store';
import { isAthlete, isBusiness, UserRoles, _confirm } from '~/services';
import { mediaLink } from '~/utils';
import { useRoute } from '~/hooks';

interface MenuItemProps {
	to: string,
	icon: IconProps['name'],
	label: string,
	count?: number
}

const MenuItem: React.FC<MenuItemProps> = (props) => {

	const { to, icon, label, count } = props;

	const { pathname } = useLocation();

	useEffect(() => {

		window.scrollTo(0, 0);

	}, [ pathname ]);

	const onClick = () => {
		if (pathname === to) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	}

	const isActive: NavLinkProps['isActive'] = (params, location) => {

		if (!params) {

			let path: string[] = [];

			switch (to) {
				case '/dashboard':
					path = [ '/@:id', '/p/@:id', '/request-form/:id/:type' ];
					break;
				case '/requests':
					path = [ '/request/:id', '/chat/:id' ];
					break;
				case '/profile':
					path = [ '/settings/:type' ];
					break;
				default:
					return false;
			}

			return !!matchPath(location.pathname, { path });

		}

		return !!matchPath(location.pathname, params);

	}

	return (
		<NavLink
			to={to}
			onClick={onClick}
			isActive={isActive}
			className="app-aside--menu-item">
			<Icon name={icon} />
			<p>{label}</p>
			{!!count && <div className="count" title={`${count}`}>{count >= 100 ? '99+' : count}</div>}
		</NavLink>
	);

}

export const Frame: React.FC = (props) => {

	const { children } = props;

	const [ isMenuShowm, setMenuVisibility ] = useAppState();

	const { route } = useRoute();

	const { status, account, unAuthorize } = useAuth();

	const { unreadCount } = useNotifications();

	const logout = async () => {

		if (!await _confirm.logout()) {
			return;
		}

		unAuthorize();

	}

	return (
		<div className={cn('app-frame', { 'has-menu': !!route?.hasMenu, 'menu-shown': isMenuShowm })}>
			<aside className="app-frame--aside">
				<div className="app-aside--content">
					<div className="app-nav--wrap">
						<div>
							<div className="logo">
								<Link to="/dashboard">
									<Logo />
								</Link>
								<SquareIcon
									icon="cross"
									onClick={() => setMenuVisibility(false)} />
							</div>
							<nav>
								<MenuItem
									to="/dashboard"
									icon="MenuDashboard"
									label="Dashboard" />
								{account && (
								<>
									{isAthlete(account) && (
									<MenuItem
										to="/locker-room"
										icon="MenuLockerRoom"
										label="Locker Room" />
									)}
									{isBusiness(account) && (
									<MenuItem
										to="/stats"
										icon="MenuLockerRoom"
										label="Statistics" />
									)}
									<MenuItem
										to="/requests"
										icon="MenuRequests"
										label="Requests" />
									<MenuItem
										to="/notifications"
										icon="MenuNotifications"
										label="Notifications"
										count={unreadCount} />
									<MenuItem
										to="/profile"
										icon="MenuProfile"
										label="Profile" />
								</>
								)}
								{status === -1 && (
								<>
									<MenuItem
										to="/login"
										icon="MenuProfile"
										label="Log In" />
								</>
								)}
							</nav>
						</div>
						{account && (
						<div className="app-nav--user-frame">
							<div className="app--nav-user">
								<img
									src={mediaLink(account.profile_photo, 'S')}
									alt={`${account.first_name} ${account.last_name}`} />
								<div className="content">
									<h4>{account.first_name} {account.last_name}</h4>
									<p>{UserRoles[account.user_type][1]}</p>
								</div>
								<SquareIcon
									icon="logout"
									onClick={logout} />
							</div>
						</div>
						)}
					</div>
				</div>
				<div
					onClick={() => setMenuVisibility(false)}
					className="app-aside--bg" />
			</aside>
			<div className="app-frame--main">
				<div className="app-main--content">{children}</div>
			</div>
		</div>
	);

}
