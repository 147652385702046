import { ReactElement } from 'react';
import cn from 'classnames';

type SkeletonType = 'athletes' | 'notifications'

interface SkeletonProps {
	type?: 'athletes' | 'notifications'
}

const types: Record<SkeletonType, ReactElement> = {
	athletes: (
		<div className="inner">
			<div className="long--item"></div>
			<div className="short--items">
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
	),
	notifications: (
		<div className="inner">
			<div className="long--item"></div>
			<div className="short--items">
				<i></i>
			</div>
		</div>
	),
}

export const Skeleton: React.FC<SkeletonProps> = (props) => {

	const { type = 'athletes' } = props;

	const classNames = cn('app--skeleton', {
		[`type--${type}`]: type,
		'grid-3s': type === 'athletes',
	});

	return (
		<div className={classNames}>
			{new Array(20).fill(null).map((_, i) => (
			<div
				key={i}
				children={types[type]}
				className="skeleton--item" />
			))}
		</div>
	);

}
