import cn from 'classnames';

import { getValidChildren } from '~/utils';

type TabArgs = {
	label: string,
	emptyText?: string
}

export interface TabProps {
	label: string,
	onChange: () => void,
	isSelected: boolean
}

export interface TabsHeadProps {
	tab: number,
	tabs: TabArgs[],
	onChange?: (tab: number) => void
}

export interface TabsProps {
	tab: number,
	tabs: TabArgs[],
	grow?: boolean,
	loading?: boolean | boolean[],
	className?: string,
	onChange?: (tab: number) => void
}

const Tab: React.FC<TabProps> = (props) => {

	const { label, onChange, isSelected } = props;

	return (
		<div
			onClick={onChange}
			className={cn('app--tabs-tab', { selected: isSelected })}>
			<p>{label}</p>
		</div>
	);

}

export const TabsHead: React.FC<TabsHeadProps> = (props) => {

	const { tab, tabs, onChange } = props;

	return (
		<div className="app--tabs-head">
			{tabs.map(({ label }, i) => (
			<Tab
				key={i}
				label={label}
				onChange={() => {
					if (onChange && i !== tab) {
						onChange(i);
					}
				}}
				isSelected={i === tab} />
			))}
		</div>
	);

}

export const Tabs: React.FC<TabsProps> = (props) => {

	const { tab, tabs, onChange, children, className } = props;

	return (
		<div className={cn('app--tabs', { single: tabs.length === 1 })}>
			<TabsHead
				tab={tab}
				tabs={tabs}
				onChange={onChange} />
			{children && (
			<div className={cn('tabs--content', className)}>
				{children ? getValidChildren(children)[tab] : children}
			</div>
			)}
		</div>
	);

}
