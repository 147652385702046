import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAuth } from '~/store';
import { Button, Input, Wrapper } from '~/components';
import { ResponseFail, SignInInput } from '~/services';
import { useFastAuth } from '~/hooks';

export const Login: React.FC = () => {

	const { signIn, loading } = useAuth();

	const { loading: fastAuthLoading } = useFastAuth('s');

	const { control, handleSubmit, setError } = useForm<SignInInput>();

	const submit = async (form: SignInInput) => {

		try {

			await signIn(form);

		} catch (e) {

			const { message } = e as ResponseFail;

			setError('password', { message });

		}

	}

	if (fastAuthLoading) {
		return null;
	}

	return (
		<div className="container container--centered">
			<Wrapper
				icon="bigArrowRight"
				title="Sign In"
				className="wrapper--login">
				<form onSubmit={handleSubmit(submit)}>
					<Input
						icon="mail"
						type="email"
						name="email"
						rules={{ required: true }}
						control={control}
						placeholder="Email" />
					<Input
						icon="lock"
						type="password"
						name="password"
						rules={{ required: true }}
						control={control}
						placeholder="Password" />
					<div className="restore-link">
						<Link to="/restore">Forgot Password?</Link>
					</div>
					<Button
						label="Log In"
						variant="primary"
						loading={loading.signIn} />
					<Button
						to="/signup"
						label="Sign Up"
						variant="secondary" />
					<Button
						to="/dashboard"
						label="Continue as guest" />
				</form>
			</Wrapper>
		</div>
	);

}
