import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useUser } from '~/store';
import { Avatar, Background, SquareIcon, Tabs } from '~/components';
import { isAthlete, UserUpdateInput, _confirm } from '~/services';
import { mediaLink } from '~/utils';
import { StateResponse } from '~/types';
import { EditTabEvent } from './types';

import {
	UserEditGeneral,
	UserEditServices,
	UserEditSocialLinks
} from '~/containers/manage/ProfileEdit';

interface ModalUserEditProps {
	athleteId: string,
	onClose: () => void,
	updated: StateResponse<boolean>
}

const tabs = [
	{ label: 'General' },
	{ label: 'Services' },
	{ label: 'Social links' },
];

export const ModalUserEdit: React.FC<ModalUserEditProps> = (props) => {

	const { athleteId, onClose, updated } = props;

	const [ isUpdated, setIsUpdated ] = updated;

	const { user, updateUser, loading } = useUser(athleteId);

	const [ tab, setTab ] = useState(0);

	const onSubmit = useCallback(
		async (event: EditTabEvent) => {

			if (!user) {
				return;
			}

			const data = { ...user.account } as UserUpdateInput;

			if (event.tab === 'general') {
				Object.assign(data, event.data);
				data.gender = event.data.gender.value;
				data.athlete_info.school_id = event.data.school.value;
				data.athlete_info.school_name = event.data.school.label;
				data.athlete_info.sports = event.data.sport.map(({ value }) => value).join(',');
			}

			if (event.tab === 'services') {
				data.athlete_info.athlete_services = event.data;
			}

			if (event.tab === 'links') {
				Object.assign(data, event.data);
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const resp = await updateUser(data);

					if (typeof resp === 'string') {
						return reject(resp);
					}

					resolve(true);

					setIsUpdated(false);

				}),
				{
					pending: 'Updating user',
					success: 'User has been updated',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ user, updateUser, setIsUpdated ]
	);

	const onTabChange = useCallback(
		async (tab: number) => {

			if (isUpdated) {

				if (!(await _confirm.editLeave(true))) {
					return;
				}

				setIsUpdated(false);

			}

			setTab(tab);

		},
		[ isUpdated, setIsUpdated ]
	);

	if (!user || !isAthlete(user?.account)) {
		return null;
	}

	const { first_name, email, profile_photo, athlete_info } = user.account;

	return (
		<div className="profile-edit">
			<div className="edit-head">
				<div className="edit-head--inner">
					<div className="edit-head--title">
						<Avatar src={profile_photo} />
						<div>
							<h1>Edit {first_name ? `${first_name}'s` : ''} profile</h1>
							<p>{email}</p>
						</div>
						<SquareIcon
							icon="cross"
							onClick={onClose} />
					</div>
					<div className="edit-head--bg-over" />
					<Background
						url={mediaLink(profile_photo, 'S')}
						className="edit-head--bg" />
				</div>
			</div>
			<Tabs
				tab={tab}
				tabs={tabs}
				onChange={onTabChange}
				className="edit--modal-wrap">
				<UserEditGeneral
					data={user.account}
					loading={loading.updateUser}
					updated={updated}
					onSubmit={onSubmit} />
				<UserEditServices
					data={athlete_info.athlete_services}
					loading={loading.updateUser}
					updated={updated}
					onSubmit={onSubmit} />
				<UserEditSocialLinks
					data={user.account}
					loading={loading.updateUser}
					updated={updated}
					onSubmit={onSubmit} />
			</Tabs>
		</div>
	);

}
