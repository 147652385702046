import { useEffect, useState } from 'react';
import AgoraRTC, { ClientConfig, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react';

import { Button } from '~/components';
import { handleError, IAccount, Request, _confirm } from '~/services';
import { useHistory } from 'react-router';

AgoraRTC.setLogLevel(4);

const config: ClientConfig = {
	mode: 'rtc',
	codec: 'vp8',
};

const useClient = createClient(config);

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

export interface VideoChatProps {
	uid: number,
	token: string,
	appId: string,
	profile: IAccount,
	requestId: string,
	channelName: string
}

export const VideoChat: React.FC<VideoChatProps> = (props) => {

	const { uid, token, appId, requestId, channelName } = props;

	const { replace } = useHistory();

	const client = useClient();

	const { ready, tracks, error } = useMicrophoneAndCameraTracks();

	const [ start, setStart ] = useState<boolean>(false);

	const [ remoteUser, setRemoteUser ] = useState<IAgoraRTCRemoteUser | null>(null);

	useEffect(
		() => {

			let init = async (name: string) => {

				client.on('user-published', async (user, mediaType) => {

					await client.subscribe(user, mediaType);

					if (mediaType === 'video') {
						setRemoteUser(user);
					}

					if (mediaType === 'audio') {
						user.audioTrack?.play();
					}

				});

				client.on('user-unpublished', (user, type) => {

					if (type === 'audio') {
						user.audioTrack?.stop();
					}

					if (type === 'video') {
						setRemoteUser(null);
					}

				});

				client.on('user-left', async (user) => {

					setRemoteUser(null);

					try {

						const { video_chat_completed } = await Request.isVideoChatFinished(requestId).promise;

						if (!video_chat_completed) {
							return;
						}

						alert('Your chat has been finished');

						replace(`/request/${requestId}`);

					} catch (e) {

						handleError(e);

					}

				});

				await client.join(appId, name, token, uid);

				if (tracks) {
					await client.publish([ tracks[0], tracks[1] ]);
				}

				setStart(true);

			}

			if (ready && tracks) {

				init(channelName);

			}

		},
		[ channelName, client, ready, tracks ]
	);

	useEffect(
		() => {

			if (!error) {
				return;
			}

			alert('An error occurred');

		},
		[ error ]
	);

	const finishCall = async () => {

		try {

			if (!await _confirm.videoChatLeave()) {
				return;
			}

			client.leave();

			await Request.finishVideoChat({
				request_id: requestId,
			}).promise;

			replace(`/request/${requestId}`);

		} catch (e) {

			handleError(e);

		}

	}

	return (
		<>
			{(start && tracks) && (
			<AgoraVideoPlayer
				className="user-preview"
				videoTrack={tracks[1]} />
			)}
			{(start && tracks && remoteUser?.videoTrack) && (
			<AgoraVideoPlayer
				key={remoteUser.uid}
				className="partner-preview"
				videoTrack={remoteUser.videoTrack} />
			)}
			{(start && tracks) && (
			<div className="leave-button">
				<Button
					label="Finish call"
					onClick={finishCall} />
			</div>
			)}
		</>
	);

}
