import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { useAuth } from '~/store';
import { isBusiness, useAthleteSearch } from '~/services';
import { AthleteCard, Button, Search, Select, Skeleton } from '~/components';
import { PageContent, PageTitle } from '~/containers';

import { CampaignPrompt } from '../containers/Modals';

export const Dashboard: React.FC = () => {

	const { account } = useAuth();

	const { athletes, loading, filter, field, text, trigger } = useAthleteSearch();

	const { push } = useHistory();

	const [ campaignPrompt, setCampaignPrompt ] = useState<boolean>(false);

	const SearchAddon = (
		<Select
			value={field}
			options={text.SEARCH_FIELDS_OPTIONS}
			onChange={({ value }) => trigger('field', value as string)} />
	);

	const PageHeader = (
		<>
			<Search
				loading={loading === 2}
				onReset={trigger}
				onSearch={(value) => trigger('filter', value)}
				placeholder={text.SEARCH_PLACEHOLDER[(field || 'name')]}
				searchAddon={SearchAddon}
				defaultValue={filter} />
			{isBusiness(account) && (
			<>
				<Button
					label="Create campaign"
					variant="primary"
					onClick={() => setCampaignPrompt(true)} />
			</>
			)}
		</>
	);

	const EmptyContent = (
		<p className="content--empty">Athletes not found</p>
	);

	return (
		<>
			<PageTitle title="Dashboard" />
			<PageContent
				data={athletes}
				loading={loading === 1}
				loadingComponent={<Skeleton />}
				renderItem={(athlete) => (
					<AthleteCard
						key={athlete.id}
						athlete={athlete}
						onClick={() => push(`/@${athlete.username}`)} />
				)}
				pageContentHeader={PageHeader}
				pageContentHeaderClassName={cn('content-search', { business: !!isBusiness(account) })}
				pageContentClassName="grid-3s"
				pageEmptyComponent={EmptyContent}>
			</PageContent>
			<CampaignPrompt
				isVisible={campaignPrompt}
				setVisile={setCampaignPrompt} />
		</>
	);

}
